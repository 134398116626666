import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import RefreshToken from "../../../services/RefreshToken";
import { getDataWithToken } from "../../../services/GetDataService";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    width: "800px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
  },
  input: {
    display: "none",
  },
}));

const AssignTeacher = ({ kidId, setActive }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [message, setMessage] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const validation = () => {
    let isError = false;

    if (!teacherId.trim()) {
      handleSnakbarOpen("Please select a teacher", "error");

      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidId}/teacher/${teacherId}`,
          method: "put",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          setActive(2);
          handleSnakbarOpen("Onboarding complete successfully", "success");
          navigate("/kid-list");
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const getData = async () => {
    // setLoading(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    

    const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/dropdown/teacher`;
    let allData = await getDataWithToken(allDataUrl, token);
    if (allData.status >= 200 && allData.status < 300) {
      setDataList(allData.data);

      if (allData.data.length < 1) {
        setMessage("No data found");
      }
    } else {
      setMessage(allData.data.message.toString());
      if (allData.data.message.length < 1) {
        setMessage("Something went wrong");
      }
    }
    // setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <form className={classes.form} onSubmit={onSubmit}>
        <Typography variant="h5" component="div">
          Assign Teacher
        </Typography>
        <hr />
        <br />
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">SELECT ONE</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="SELECT ONE"
                value={teacherId}
                onChange={(e) => setTeacherId(e.target.value)}
              >
                {dataList?.map((item, i) => (
                  <MenuItem value={item.id} key={i}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          disableElevation
          style={{
            marginTop: "50px",
            display: "block",
            marginLeft: "auto",
            minWidth: "150px",
          }}
          disabled={loading}
          type="submit"
        >
          {loading === false && "Continue"}
          <PulseLoader
            color={"#353b48"}
            loading={loading}
            size={10}
            speedMultiplier={0.5}
          />{" "}
        </Button>
      </form>
    </div>
  );
};

export default AssignTeacher;

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "10px",
  border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "12px 24px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const KidAssessment = () => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [message, setMessage] = useState("");
  const [authorizedGuardianList, setAuthorizedGuardianList] = useState([]);
  const [authorizedGuardianDetail, setAuthorizedGuardianDetail] = useState({});
  const [assessmentList, setAssessmentList] = useState([]);

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getData = async () => {
    setLoading(true);
    setMessage("");
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const assessmentUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/assessment/results`;
    let response = await getDataWithToken(assessmentUrl, token);
    console.log("response?.data", response?.data);
    if (response.status >= 200 && response.status < 300) {
      if (response?.data.length > 0) {
        setAssessmentList(response?.data);
        setAuthorizedGuardianList(response?.data);
        setAuthorizedGuardianDetail(response?.data[0]);
        setActiveIndex(0);
      } else {
        setMessage("No data found");
      }
    } else {
      setMessage(response.data.messages.toString());
      if (response.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
    setLoading(false);
  };

  const getNextAndPrevious = (type) => {
    if (type === "next") {
      if (authorizedGuardianList?.length - 1 > activeIndex) {
        setActiveIndex(parseInt(activeIndex + 1));
        setAuthorizedGuardianDetail(
          authorizedGuardianList[parseInt(activeIndex + 1)]
        );
      }
    } else {
      if (activeIndex > 0) {
        setAuthorizedGuardianDetail(
          authorizedGuardianList[parseInt(activeIndex - 1)]
        );
        setActiveIndex(parseInt(activeIndex - 1));
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="div"
            style={{ position: "relative", fontSize: "18px" }}
          >
            Assessment
          </Typography>
        </Grid>
      </Grid>

      <hr />
      <br />
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "250px", background: "#f5f5f5" }}
        >
          <Typography variant="h5" component="div" style={{ color: "#999999" }}>
            {message}
          </Typography>
        </Grid>
      ) : (
        <>
          
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" height={240} />
              ) : (
                <div
                  style={{
                    padding: "0px 20px",
                    height: "250px",
                    overflowY: "scroll",
                  }}
                >
                  {assessmentList?.map((item, i) => (
                    <Accordion
                      expanded={expanded === i}
                      onChange={handleChange(i)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Typography>{item.categoryName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={{
                            // padding: "10px 0px",
                            border: "1px solid #cccccc",
                          }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={8}>
                              <Typography
                                variant="h6"
                                style={{
                                  padding: "5px 10px",
                                  background: "#dbdbdb",
                                }}
                              >
                                {" "}
                                Topic
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="h6"
                                style={{
                                  textAlign: "right",
                                  padding: "5px 10px",
                                  background: "#dbdbdb",
                                }}
                              >
                                {" "}
                                Score
                              </Typography>
                            </Grid>
                          </Grid>
                          {item?.marks?.map((el, i) => (
                            <Grid
                              container
                              alignItems="center"
                              style={{ borderBottom: "1px solid #cccccc" }}
                            >
                              <Grid item xs={8}>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    padding: "5px 10px 0px 10px",
                                  }}
                                >
                                  {el.topicName}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{
                                    padding: "0px 10px 0px 10px",
                                  }}
                                >
                                  {el.assessmentDate}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    textAlign: "right",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {el.obtainedMarks} / {el.totalMarks}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default KidAssessment;

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, TextField } from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDropzone } from "react-dropzone";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px",
    background: "#fff",
    borderRadius: "10px",
    width: "700px",
    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "900px",
    },
  },
  input: {
    display: "none",
  },
  genderTitle: {
    fontSize: "18px !important",
    margin: "0 0 5px",
  },
}));
const FilesAndDocuments = () => {
  const { acceptedFiles, getRootProps, getInputProps, inputRef } =
    useDropzone();
 
  const classes = useStyles();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [fileAndDocumentList, setFileAndDocumentList] = useState([]);
  const [formDialog, setFormDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const removeAll = () => {
   
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = "";
 
  };
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));
  const handleFormDialogClose = () => {
    setFormDialog(false);
    setTitle("");
    setNotes("");
    setImageFile(null);
    removeAll();
  };
  const getData = async () => {
    setLoading(true);
    setMessage("");
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const parentIdUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/documents`;
    let response = await getDataWithToken(parentIdUrl, token);
     
    if (response.status >= 200 && response.status < 300) {
      if (response?.data.length > 0) {
        setFileAndDocumentList(response?.data);
      } else {
        setMessage("No data found");
      }
    } else {
      setMessage(response.data.messages.toString());
      if (response.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
    setLoading(false);
  };
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 5; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(2).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };
  const validation = () => {
    let isError = false;
    if (!title.trim()) {
      handleSnakbarOpen("Please enter title", "error");
      document.getElementById("title").focus();
      return (isError = true);
    }

    if (acceptedFiles.length < 1) {
      handleSnakbarOpen("Please upload file", "error");

      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let response = {};
        for (let index = 0; index < acceptedFiles.length; index++) {
          const item = acceptedFiles[index];

          let formData = new FormData();
          formData.append("title", title);
          formData.append("notes", notes);
          formData.append("file", item);

          let token = await RefreshToken(
            kinder_cubby_panel_user,
            logout,
            login
          );

          response = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/documents`,
            method: "post",
            data: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Added file and document successfully", "success");
       
          setTitle("");
          setNotes("");
          removeAll();
          setImageFile(null);
          setFormDialog(false);
          getData();
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);

        handleSnakbarOpen(error.response.data.message, "error");
      }
      setLoading(false);
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteData({});
  };
  const handleDeleteDialog = (i, row) => {
    setDeleteData({ index: i, row: row });
    setDeleteDialog(true);
  };
  const handleDelete = async () => {
    try {
      setLoading2(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}//documents/${deleteData.row.id}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Deleted successfully", "success");
        getData();
      }
      setDeleteDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      setLoading2(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setDeleteDialog(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs="auto">
          <Typography
            variant="h6"
            component="div"
            style={{ position: "relative", fontSize: "18px" }}
          >
            Files and Documents
          </Typography>
        </Grid>
        <Grid item xs="auto" style={{ textAlign: "right" }}>
          <IconButton
            size="small"
            style={{
              color: "#999999",
              border: "1px solid #999999",
            }}
            onClick={() => setFormDialog(true)}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <hr />
      <br />
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "250px", background: "#f5f5f5" }}
        >
          <Typography variant="h5" component="div" style={{ color: "#999999" }}>
            {message}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" height={110} />
              ) : (
                <div
                  style={{
                    height: "250px",
                    overflowY: "scroll",
                  }}
                >
                  <Grid container>
                    <Table aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell align="center">Preview</TableCell>
                          <TableCell align="center">Uploaded At</TableCell>

                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!loading &&
                          fileAndDocumentList.length > 0 &&
                          fileAndDocumentList.map((row, i) => (
                            <TableRow
                              key={i}
                              // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell>{row?.title}</TableCell>

                              <TableCell align="center">
                                <a href={`${row?.file}`} target="_blank">
                                  Preview
                                </a>
                              </TableCell>
                              <TableCell>
                                {" "}
                                {moment(row?.createdAt).format(
                                  "Do MMM, YYYY, h:mm a"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  variant="contained"
                                  disableElevation
                                  onClick={() => handleDeleteDialog(i, row)}
                                >
                                  <DeleteOutlineIcon color="error" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}

                        {!loading && fileAndDocumentList.length < 1 ? (
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{ textAlign: "center" }}
                            >
                              <strong> {message}</strong>
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {loading && pageLoading()}
                      </TableBody>
                    </Table>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Dialog
        open={formDialog}
        onClose={handleFormDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle style={{ position: "relative" }} id="alert-dialog-title">
            {"Add File and Document"}
            <IconButton
              onClick={handleFormDialogClose}
              style={{ position: "absolute", right: 15, top: 8 }}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={onSubmit} style={{ marginTop: "10px" }}>
              <Grid container justifyContent="center">
                <TextField
                  style={{ marginBottom: "30px" }}
                  label="Title"
                  id="title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                <TextField
                  style={{ marginBottom: "30px" }}
                  label="Notes"
                  id="notes"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
                <section className="container" style={{ width: "100%" }}>
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    style={{
                      border: "1px solid #ddd",
                      height: "80px",
                      background: "#efefef",
                      borderRadius: "4px",
                    }}
                  >
                    <input {...getInputProps()} />
                    <p style={{ textAlign: "center" }}>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                  <aside>
                    {/* <h4>Files</h4> */}
                    <ul>{files}</ul>
                  </aside>
                </section>

                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={loading}
                  type="submit"
                  style={{ minHeight: "37px" }}
                >
                  {loading === false && "Continue"}
                  <PulseLoader
                    color={"#353b48"}
                    loading={loading}
                    size={10}
                    speedMultiplier={0.5}
                  />{" "}
                </Button>
              </Grid>
            </form>
          </DialogContent>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to delete {deleteData?.row?.title}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleDelete}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default FilesAndDocuments;

import React from "react";
import Layout from "./pages/layout/Layout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthContextProvider from "./context/AuthContext";
import Slide from "@mui/material/Slide";
import axios from "axios";
import { SnackbarProvider } from "notistack";
import "./App.css";
const theme = createTheme({
  palette: {
    primary: {
      // main: "#353b48",
      main: "#17202A",
      contrastText: "#fff",
    },
    info: {
      main: "#273c75",
      contrastText: "#fff",
    },
    error: {
      main: "#F91351",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      [createTheme().breakpoints.down("xl")]: {
        fontSize: "4rem",
      },
    },
    h4: {
      [createTheme().breakpoints.down("xl")]: {
        fontSize: "1.75rem",
      },
    },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1250,
  //     xl: 1600,
  //   },
  // },
});
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
function App() {
  return (
    <div>
      {/* <div style={{ maxWidth: "1366px", margin: "auto" }}> */}

      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
        >
          <AuthContextProvider>
            <Layout />
          </AuthContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React, { useState, useRef } from "react";
import { Camera } from "react-camera-pro";
import Webcam from "react-webcam";

const Test = () => {
  const camera = useRef(null);
  const [image, setImage] = useState(null);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  return (
    <div>
      {/* <div>
        <Camera ref={camera} aspectRatio={4 / 3} />
        <button onClick={() => setImage(camera.current.takePhoto())}>
          Take photo
        </button>
        <img src={image} alt="Taken photo" />
      </div> */}
      <Webcam
        audio={false}
        height={720}
        screenshotFormat="image/jpeg"
        width={1280}
        videoConstraints={videoConstraints}
      >
        {({ getScreenshot }) => (
          <button
            onClick={() => {
              const imageSrc = getScreenshot();
            }}
          >
            Capture photo
          </button>
        )}
      </Webcam>
    </div>
  );
};

export default Test;

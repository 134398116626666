import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import { getDataWithToken } from "../../../services/GetDataService";
import { countryList } from "../../../data/CountryList";
import RefreshToken from "../../../services/RefreshToken";
import MyCamera from "../../../services/MyCamera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    width: "1000px",
    boxSizing: "border-box",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  optionStyle: {
    // border: "1px solid #079992",
    padding: "0px 12px",
    borderRadius: "10px",
    [theme.breakpoints.down("xl")]: {
      padding: "5px 10px",
    },
  },
  input: {
    display: "none",
  },
}));

const UpdateParent = ({ routeToTeacherList, handleUpdateDialogClose }) => {
  const { slug } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const uploadImage = "/image/parent.jpg";
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [locality, setLocality] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(uploadImage);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const validation = () => {
    let isError = false;

    if (!firstName.trim()) {
      handleSnakbarOpen("Please enter first name", "error");
      document.getElementById("firstName").focus();
      return (isError = true);
    }
    if (!lastName.trim()) {
      handleSnakbarOpen("Please enter last name", "error");
      document.getElementById("lastName").focus();
      return (isError = true);
    }
    if (!email.trim()) {
      handleSnakbarOpen("Please enter email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email.trim()
      )
    ) {
      handleSnakbarOpen("Invalid email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    }

    if (!phone.trim()) {
      handleSnakbarOpen("Please enter phone number", "error");
      document.getElementById("phone").focus();
      return (isError = true);
    }
    if (!street.trim()) {
      handleSnakbarOpen("Please enter street", "error");
      document.getElementById("street").focus();
      return (isError = true);
    }
    if (!locality.trim()) {
      handleSnakbarOpen("Please enter locality", "error");
      document.getElementById("locality").focus();
      return (isError = true);
    }
    if (!region.trim()) {
      handleSnakbarOpen("Please enter state", "error");
      document.getElementById("region").focus();
      return (isError = true);
    }
    if (!postalCode.trim()) {
      handleSnakbarOpen("Please enter zip code", "error");
      document.getElementById("postalCode").focus();
      return (isError = true);
    }
    if (!country.trim()) {
      handleSnakbarOpen("Please select a country", "error");
      document.getElementById("country").focus();
      return (isError = true);
    }
    return isError;
  };

  const onSubmit = async (type) => {
    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phoneNumber", phone);
        formData.append("street", street);
        formData.append("locality", locality);
        formData.append("region", region);
        formData.append("postalCode", postalCode);
        formData.append("country", country);
        formData.append("enabled", status);
        if (imageFile !== null) {
          formData.append("photo", imageFile);
        }

        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/${userId}`,
          method: "put",
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Updated successfully", "success");
          if (routeToTeacherList !== false) {
            navigate("/parent-list");
          } else {
            handleUpdateDialogClose();
          }
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errorMessage.toString(), "error");
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const getdata = async () => {
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    let updateurl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/${slug}`;
    let teacherData = await getDataWithToken(updateurl, token);
    if (teacherData.status === 200) {
      setUserId(teacherData?.data?.id);
      setFirstName(teacherData?.data?.firstName);
      setLastName(teacherData?.data?.lastName);
      setEmail(teacherData?.data?.email);
      setStatus(teacherData?.data?.enabled);
      setPhone(teacherData?.data?.attributes?.phoneNumber[0]);
      setStreet(teacherData?.data?.attributes?.street[0]);
      setLocality(teacherData?.data?.attributes?.locality[0]);
      setRegion(teacherData?.data?.attributes?.region[0]);
      setPostalCode(teacherData?.data?.attributes?.postalCode[0]);
      setCountry(teacherData?.data?.attributes?.country[0]);
      setPreview(teacherData?.data?.attributes?.photo[0]);
      // setRoleListForDropdown(teacherData.data.data.data);
    } else {
      // setRoleListForDropdownMsg(teacherData.data.messages.toString());
      if (teacherData.data.message.length < 1) {
        // setRoleListForDropdownMsg("Something went wrong");
      }
    }
  };
  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ maxHeight: "80vh" }}
      >
        <form className={classes.form}>
          <Typography
            variant="h5"
            component="div"
            // style={{ marginBottom: "30px" }}
          >
            Update Guardian Information
          </Typography>
          <hr />
          <br />
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={preview}
                  alt=""
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("contained-button-file").click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginTop: "5px", width: "140px", padding: 4 }}
                  onClick={() =>
                    document.getElementById("contained-button-file").click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload
                </Button>
                &nbsp;
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginTop: "5px" }}
                  onClick={() => setOpenCamera(true)}
                >
                  <CameraAltOutlinedIcon />
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={imageProcess}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                label="First Name"
                id="firstName"
                variant="outlined"
                size="small"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Last Name"
                id="lastName"
                variant="outlined"
                size="small"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Email"
                id="email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Phone"
                id="phone"
                variant="outlined"
                size="small"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Street"
                id="street"
                variant="outlined"
                size="small"
                fullWidth
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="City"
                id="locality"
                variant="outlined"
                size="small"
                fullWidth
                value={locality}
                onChange={(e) => setLocality(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="State"
                id="region"
                variant="outlined"
                size="small"
                fullWidth
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Zip Code"
                id="postalCode"
                variant="outlined"
                size="small"
                fullWidth
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-country-select-label">Country</InputLabel>
                <Select
                  labelId="demo-country-select-label"
                  id="demo-country-select"
                  label="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countryList?.map((item, i) => (
                    <MenuItem value={item.name} key={i}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            disableElevation
            style={{
              marginTop: "30px",
              display: "block",
              marginLeft: "auto",
              minWidth: "150px",
            }}
            disabled={loading}
            onClick={() => onSubmit("info")}
          >
            {loading === false && "Update"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </form>
      </Grid>
      <MyCamera
        setImageFile={setImageFile}
        setPreview={setPreview}
        openCamera={openCamera}
        setOpenCamera={setOpenCamera}
      />
    </>
  );
};

export default UpdateParent;

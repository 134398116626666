import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import RefreshToken from "../../../services/RefreshToken";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import MyCamera from "../../../services/MyCamera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px",
    background: "#fff",
    borderRadius: "10px",
    width: "1200px",
    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "900px",
    },
  },
  input: {
    display: "none",
  },
  genderTitle: {
    fontSize: "18px !important",
    margin: "0 0 5px",
  },
  gridStyle: {
    border: "1px solid #999999",
    padding: "20px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
}));

const AddKid = ({ setKidId, setActive }) => {
  const classes = useStyles();
  const uploadImage = "/image/kid.jpg";
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [allergies, setAllergies] = useState("");
  const [likes, setLikes] = useState("");
  const [hourlyRate, setRourlyRate] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [chargeType, setChargeType] = useState("Hour");
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(uploadImage);
  const { enqueueSnackbar } = useSnackbar();
  const [openCamera, setOpenCamera] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };

  const validation = () => {
    let isError = false;
    if (imageFile === null) {
      handleSnakbarOpen("Please upload a image", "error");

      return (isError = true);
    }
    if (!firstName.trim()) {
      handleSnakbarOpen("Please enter first name", "error");
      document.getElementById("firstName").focus();
      return (isError = true);
    }
    if (!lastName.trim()) {
      handleSnakbarOpen("Please enter last name", "error");
      document.getElementById("lastName").focus();
      return (isError = true);
    }
    if (dob === null) {
      handleSnakbarOpen("Please enter date of birth", "error");

      return (isError = true);
    }

    if (!gender.trim()) {
      handleSnakbarOpen("Please select gender", "error");
      document.getElementById("password").focus();
      return (isError = true);
    }

    return isError;
  };

  const addHourlyRates = async (kidId) => {
    try {
      let formData = new FormData();
      if (hourlyRate.length > 0) {
        formData.append("charge", parseFloat(hourlyRate).toFixed(2));
        formData.append("per", chargeType);
      } else {
        formData.append("charge", parseFloat("0.00").toFixed(2));
        formData.append("per", chargeType);
      }
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidId}/rates`,
        method: "post",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen("Something went wrong to add add hourly rate", "error");
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("dateOfBirth", moment(dob).format("YYYY-MM-DD"));
        formData.append("gender", gender);
        formData.append("photo", imageFile);
        formData.append("status", true);

        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid`,
          method: "post",
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          addHourlyRates(response.data.kidId);
          setKidId(response.data.kidId);
          const kidId = response.data.kidId;
          let allergiesArray = allergies.split(",");
          let likesArray = likes.split(",");
          allergiesArray.map((item) => {
            let alg = new FormData();

            alg.append("tag", item.trim());
            axios({
              url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidId}/allergies`,
              method: "post",
              data: alg,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          });

          likesArray.map((item) => {
            let like = new FormData();

            like.append("tag", item.trim());
            axios({
              url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidId}/likes`,
              method: "post",
              data: like,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          });

          setActive(1);
          handleSnakbarOpen("Added new kid successfully", "success");
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container>
          <Grid item md={7.5} lg={7.5} xl={6.9} className={classes.gridStyle}>
            <Typography
              variant="h5"
              component="div"
              // style={{ marginBottom: "30px" }}
            >
              Kid Information
            </Typography>
            <hr />
            <br />
            <Grid container>
              <Grid item xs={8}>
                <TextField
                  style={{ marginBottom: "30px" }}
                  label="First Name"
                  id="firstName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <TextField
                  style={{ marginBottom: "30px" }}
                  label="Last Name"
                  id="lastName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="yyyy-MM-dd"
                    label="Date of Birth"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        style={{ marginBottom: "30px" }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <Grid
                  container
                  alignItems="center"
                  style={{ marginBottom: "30px" }}
                >
                  <Grid item xs={3}>
                    <p
                      className={classes.genderTitle}
                      component="div"
                      // style={{ marginBottom: "30px" }}
                    >
                      Gender :
                    </p>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "100%" }}>
                      <RadioGroup
                        row
                        style={{ justifyContent: "space-between" }}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <FormControlLabel
                          value="Male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="Female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="Not_Applicable"
                          control={<Radio />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center" }}>
                  <input
                    accept="image/png, image/jpg, image/jpeg"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={imageProcess}
                  />
                  <img
                    src={preview}
                    alt=""
                    style={{
                      height: "140px",
                      width: "140px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "5px",
                      borderRadius: "50%",
                    }}
                    onClick={() =>
                      document.getElementById("contained-button-file").click()
                    }
                  />
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ marginTop: "5px", width: "100px", padding: 4 }}
                    onClick={() =>
                      document.getElementById("contained-button-file").click()
                    }
                    startIcon={<FileUploadOutlinedIcon />}
                  >
                    Upload
                  </Button>
                  &nbsp;
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ marginTop: "5px" }}
                    onClick={() => setOpenCamera(true)}
                  >
                    <CameraAltOutlinedIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={0.2} lg={0.2} xl={0.2}></Grid>
          <Grid item md={4.3} lg={4.3} xl={4.9} className={classes.gridStyle}>
            <Typography
              variant="h5"
              component="div"
              // style={{ marginBottom: "30px" }}
            >
              Additional Information (if any)
            </Typography>
            <hr />
            <br />
            <TextField
              style={{ marginBottom: "30px" }}
              label="Allergies (if any)"
              id="allergies"
              variant="outlined"
              size="small"
              fullWidth
              value={allergies}
              onChange={(e) => setAllergies(e.target.value)}
            />
            <TextField
              style={{ marginBottom: "30px" }}
              label="Likes"
              id="likes"
              variant="outlined"
              size="small"
              fullWidth
              value={likes}
              onChange={(e) => setLikes(e.target.value)}
            />
            <Typography variant="h5" component="div">
              Charge
            </Typography>
            <hr />
            <br />
            <Grid
              container
              alignItems="center"
              columnSpacing={2}
              style={{ marginBottom: "30px" }}
            >
              <Grid item xl={6}>
                <TextField
                  // style={{ marginBottom: "30px" }}
                  type="number"
                  label="Amount"
                  id="hourlyRate"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={hourlyRate}
                  onChange={(e) => setRourlyRate(e.target.value)}
                />
              </Grid>
              <Grid item xl={6}>
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "space-between" }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={chargeType}
                    onChange={(e) => setChargeType(e.target.value)}
                  >
                    <FormControlLabel
                      value="Hour"
                      control={<Radio />}
                      label="Hour"
                    />
                    <FormControlLabel
                      value="Month"
                      control={<Radio />}
                      label="Month"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ marginTop: "30px" }}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            disabled={loading}
            type="submit"
            style={{ minHeight: "37px" }}
          >
            {loading === false && "Save & Continue"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </div>
      </form>
      <MyCamera
        setImageFile={setImageFile}
        setPreview={setPreview}
        openCamera={openCamera}
        setOpenCamera={setOpenCamera}
      />
    </div>
  );
};

export default AddKid;

import React, { useCallback, useState } from "react";

import {
  GoogleMap,
  StandaloneSearchBox,
  LoadScript,
  Marker,
} from "@react-google-maps/api";

const Map = ({ marker, setMarker, center, setCenter, showSearch }) => {
  const lib = ["places"];
  const [searchBox, setSearchBox] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const onMapClick = useCallback((e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  const onPlacesChanged = () => { 
    let place = searchBox.getPlaces();
    let newLat = place[0]["geometry"]["location"].lat();
    let newLag = place[0]["geometry"]["location"].lng(); 
    setMarker({ lat: newLat, lng: newLag });
    setCenter({ lat: newLat, lng: newLag });
    setRefresh(!refresh);
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };
  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControlOptions: {
      mapTypeIds: [],
    },
  };
  const onLoad = (marker) => { 
  };
   
  return (
    <LoadScript
      // googleMapsApiKey="AIzaSyDWCZsW32gbxrr3RIDruo0_xh9Z6kCDOuA"
      googleMapsApiKey={`${process.env.REACT_APP_MAP_API_KEY}`}
      libraries={lib}
    >
      <GoogleMap
        mapContainerStyle={{ height: "300px", width: "auto" }}
        center={center}
        zoom={10}
        options={mapOptions}
        onClick={onMapClick}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          <Marker onLoad={onLoad} position={marker} />
          {showSearch && (
            <StandaloneSearchBox
              onPlacesChanged={onPlacesChanged}
              onLoad={onSBLoad}
            >
              <input
                type="text"
                placeholder="Search location"
                style={{
                  boxSizing: "border-box",
                  border: `1px solid transparent`,
                  width: `270px`,
                  height: `40px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  margin: "center",
                  textOverflow: `ellipses`,
                  position: "absolute",
                  top: "5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  // marginLeft: "50%",
                  // margin: "auto",
                  // display: "block",
                }}
              />
            </StandaloneSearchBox>
          )}
        </>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;

import React, { useState, useRef } from "react";
import { Camera } from "react-camera-pro";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const MyCamera = ({ setPreview, setImageFile, openCamera, setOpenCamera }) => {
  const camera = useRef(null);

  const handleClose = () => {
    setOpenCamera(false);
  };

  return (
    <>
      <Dialog
        open={openCamera}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogContent
          style={{ height: "403px", width: "450px", padding: "8px 8px 0" }}
        >
          <Camera ref={camera} facingMode="user" aspectRatio={10 / 9} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              const url = camera.current.takePhoto();
              fetch(url)
                .then((res) => res.blob())
                .then((blob) => {
                  const file = new File([blob], "untitled.jpeg", {
                    type: "image/jpeg",
                  });

                  setPreview(url);
                  setImageFile(file);
                  handleClose();
                });
            }}
            autoFocus
          >
            Take photo
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyCamera;

import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import RefreshToken from "../../services/RefreshToken";
import MyCamera from "../../services/MyCamera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px",
    background: "#fff",
    borderRadius: "10px",
    width: "700px",
    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "900px",
    },
  },
  input: {
    display: "none",
  },
  genderTitle: {
    fontSize: "18px !important",
    margin: "0 0 5px",
  },
}));

const UpdateBook = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const uploadImage = "/image/upload.png";
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [genre, setGenre] = useState("");
  const [authors, setAuthors] = useState("");
  const [publisher, setPublisher] = useState("");
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(uploadImage);
  const [openCamera, setOpenCamera] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };

  const validation = () => {
    let isError = false;

    if (!title.trim()) {
      handleSnakbarOpen("Please enter title", "error");
      document.getElementById("title").focus();
      return (isError = true);
    }
    if (!genre.trim()) {
      handleSnakbarOpen("Please enter genre", "error");
      document.getElementById("genre").focus();
      return (isError = true);
    }
    if (!authors.trim()) {
      handleSnakbarOpen("Please enter authors", "error");
      document.getElementById("authors").focus();
      return (isError = true);
    }
    if (!publisher.trim()) {
      handleSnakbarOpen("Please enter publisher", "error");
      document.getElementById("publisher").focus();
      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("title", title);
        formData.append("genre", genre);
        formData.append("authors", authors);
        formData.append("publisher", publisher);
        formData.append("status", status);
        if (imageFile !== null) {
          formData.append("coverImage", imageFile);
        }
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/configuration/book/${state.row.id}`,
          method: "put",
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Added new book successfully", "success");
          navigate("/book-list");
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
        setLoading(false);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    setTitle(state?.row?.title);
    setGenre(state?.row?.genre);
    setAuthors(state?.row?.authors);
    setPublisher(state?.row?.publisher);
    setPreview(state?.row?.coverImage);
    setStatus(state?.row?.status);
  }, []);

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "calc(100vh - 120px)" }}
      >
        <form className={classes.form} onSubmit={onSubmit}>
          <Typography variant="h5" component="div">
            Update Book
          </Typography>
          <hr />
          <br />
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <TextField
                style={{ marginBottom: "30px" }}
                label="Title"
                id="title"
                variant="outlined"
                size="small"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <TextField
                style={{ marginBottom: "30px" }}
                label="Genre"
                id="genre"
                variant="outlined"
                size="small"
                fullWidth
                value={genre}
                onChange={(e) => setGenre(e.target.value)}
              />
              <TextField
                style={{ marginBottom: "30px" }}
                label="Authors"
                id="authors"
                variant="outlined"
                size="small"
                fullWidth
                value={authors}
                onChange={(e) => setAuthors(e.target.value)}
              />

              <TextField
                style={{ marginBottom: "30px" }}
                label="Publisher"
                id="publisher"
                variant="outlined"
                size="small"
                fullWidth
                value={publisher}
                onChange={(e) => setPublisher(e.target.value)}
              />
              <FormControl
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
              <Button
                fullWidth
                variant="contained"
                disableElevation
                disabled={loading}
                type="submit"
              >
                {loading === false && "Continue"}
                <PulseLoader
                  color={"#353b48"}
                  loading={loading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={preview}
                  alt=""
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("contained-button-file").click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginTop: "5px", width: "100px", padding: 4 }}
                  onClick={() =>
                    document.getElementById("contained-button-file").click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload
                </Button>
                &nbsp;
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginTop: "5px" }}
                  onClick={() => setOpenCamera(true)}
                >
                  <CameraAltOutlinedIcon />
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={imageProcess}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
      <MyCamera
        setImageFile={setImageFile}
        setPreview={setPreview}
        openCamera={openCamera}
        setOpenCamera={setOpenCamera}
      />
    </div>
  );
};

export default UpdateBook;

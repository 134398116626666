import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { AuthContext } from "../../context/AuthContext";
import RefreshToken from "../../services/RefreshToken";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
const InvoiceList = ({ rollPermission }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [detailDialog, setDetailDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [detailData, setDetailData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isEditForm, setIsEditForm] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [amount, setamount] = useState("");
  const [remarks, setRemarks] = useState("");
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleDetailDialogClose = () => {
    setDetailDialog(false);
    setDetailData({});
  };

  const handleDeleteDialog = (i, row) => {
    setDetailData({ index: i, row: row });
    setDetailDialog(true);
  };
  const validation = () => {
    let isError = false;

    if (!paymentType.trim()) {
      handleSnakbarOpen("Please select a payment type method", "error");
      document.getElementById("paymentType").focus();
      return (isError = true);
    }
    if (!amount.trim()) {
      handleSnakbarOpen("Please enter amount", "error");
      document.getElementById("amount").focus();
      return (isError = true);
    }

    return isError;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setSubmitLoading(true);
      try {
        let data = {
          kidId: detailData?.kid?.kidId,
          stripeInvoiceId: detailData?.stripeInvoiceId,
          paymentType: paymentType,
          amount: parseInt(amount),
          remarks: remarks,
        };

        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/payment/manual`,
          method: "put",
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Updated successfully", "success");
          getData(0);
          handleDetailDialogClose();
        }
      } catch (error) {
        console.log("error", error);
        setSubmitLoading(false);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
      }
      setSubmitLoading(false);
    }
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(8).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO) => {
    try {
      setLoading(true);
      let newPageNO = page;

      if (pageNO >= 0) {
        newPageNO = pageNO;
      }
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/invoices/kid?page=${newPageNO}`;
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        setTableDataList(allData?.data?.content);
        setRowsPerPage(allData?.data?.size);
        setTotalData(allData?.data?.totalElements);

        if (allData.data.content.length < 1) {
          setMessage("No data found");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const showDetail = (data, editForm) => {
    setIsEditForm(editForm);
    setDetailData(data);
    setDetailDialog(true);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          color="info"
          gutterBottom
          component="div"
          style={{ background: "#ddd", padding: "10px 16px" }}
        >
          <TableChartIcon
            style={{ position: "relative", top: "4px", fontSize: "20px" }}
          />{" "}
          Invoice List
        </Typography>

        <div
          style={{
            overflowX: "auto",

            minWidth: "100%",
            // width: "Calc(100vw - 367px)",
            padding: "10px 16px 0px",
            boxSizing: "border-box",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="80px" align="center">
                  Image
                </TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Billing Month</TableCell>
                <TableCell>Charge</TableCell>
                <TableCell>Time Spent</TableCell>

                <TableCell align="center">Status</TableCell>
                <TableCell align="center" style={{ width: "180px" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tableDataList.length > 0 &&
                tableDataList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      <img
                        src={
                          row?.kid?.photo.length > 0
                            ? row?.kid?.photo
                            : "/image/NoImage.jpg"
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{row?.kid?.firstName}</TableCell>
                    <TableCell>{row?.kid?.lastName}</TableCell>
                    <TableCell>{row?.billingMonth}</TableCell>
                    <TableCell>
                      {row?.charge}{" "}
                      <span style={{ textTransform: "uppercase" }}>
                        {" "}
                        {row?.currency}
                      </span>{" "}
                      / {row?.per}
                    </TableCell>
                    <TableCell>{row?.timeSpent}</TableCell>

                    {/* <TableCell>
                      {moment(row?.createdAt).format("YYYY-MM-DD")}
                    </TableCell> */}
                    <TableCell align="center">
                      {row?.status ? (
                        <span style={{ color: "#10ac84" }}>PAID</span>
                      ) : (
                        <span style={{ color: "#ee5253" }}>UNPAID</span>
                      )}
                    </TableCell>

                    <TableCell align="center" style={{ minWidth: "280px" }}>
                      <Button
                        variant="outlined"
                        // color="success"
                        disableElevation
                        onClick={() => showDetail(row, false)}
                        startIcon={<VisibilityOutlinedIcon />}
                      >
                        {" "}
                        View
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        variant="outlined"
                        color="warning"
                        disableElevation
                        onClick={() => showDetail(row, true)}
                        startIcon={<EditOutlinedIcon />}
                      >
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && tableDataList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {tableDataList.length > 0 ? (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <br />
        )}
      </div>
      <Dialog
        open={detailDialog}
        onClose={handleDetailDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle style={{ position: "relative" }} id="alert-dialog-title">
            {isEditForm ? "Update Invoice" : "Invoice Details"}
            <IconButton
              onClick={handleDetailDialogClose}
              style={{ position: "absolute", right: 15, top: 8 }}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div>
              {" "}
              <div style={{ textAlign: "center", marginBottom: "15px" }}>
                <img
                  src={
                    detailData?.kid?.photo.length > 0
                      ? detailData?.kid?.photo
                      : "/image/NoImage.jpg"
                  }
                  alt=""
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />
                <Typography variant="h6">
                  {" "}
                  {detailData?.kid?.firstName} {detailData?.kid?.lastName}
                </Typography>
              </div>
              <div>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Date of Birth
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.kid?.dateOfBirth}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Gender
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.kid?.gender}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Charge
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.charge} / {detailData?.per}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Time Spent
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.timeSpent}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Amount Payable
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.amountPayable}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Billing Month
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.billingMonth}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Currency
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textTransform: "uppercase" }}
                    >
                      {detailData?.currency}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Payment Type
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.paymentType?.length > 1
                        ? detailData?.paymentType
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Created At
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.createdAt}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Updated At
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.updatedAt}
                    </Typography>
                  </Grid>
                  {isEditForm ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          size="small"
                          style={{ marginBottom: "30px", marginTop: "30px" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Payment Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="paymentType"
                            label="Payment Type"
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)}
                          >
                            <MenuItem value={"CASH_PAYMENT"}>
                              CASH PAYMENT
                            </MenuItem>
                            <MenuItem value={"STRIPE_PAYMENT"}>
                              STRIPE PAYMENT
                            </MenuItem>
                            <MenuItem value={"CHEQUE_PAYMENT"}>
                              CHEQUE PAYMENT
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          style={{ marginBottom: "30px" }}
                          label="Amount"
                          id="amount"
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="number"
                          value={amount}
                          onChange={(e) => setamount(e.target.value)}
                        />
                        <TextField
                          style={{ marginBottom: "30px" }}
                          label="Remarks"
                          id="remarks"
                          variant="outlined"
                          size="small"
                          fullWidth
                          multiline
                          rows={3}
                          value={remarks}
                          onChange={(e) => setRemarks(e.target.value)}
                        />

                        <Button
                          fullWidth
                          variant="contained"
                          disableElevation
                          disabled={submitLoading}
                          onClick={onSubmit}
                          style={{ minHeight: "37px" }}
                        >
                          {submitLoading === false && "Submit"}
                          <PulseLoader
                            color={"#353b48"}
                            loading={submitLoading}
                            size={10}
                            speedMultiplier={0.5}
                          />{" "}
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingBottom: "5px",
                        }}
                      >
                        {" "}
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Status
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.status === true ? (
                            <span style={{ color: "#10ac84" }}>
                              <b>PAID</b>
                            </span>
                          ) : (
                            <span style={{ color: "#ee5253" }}>
                              <b>UNPAID</b>
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: "right",
                          paddingBottom: "5px",
                        }}
                      >
                        {" "}
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          remarks
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.remarks?.length > 1
                            ? detailData?.remarks
                            : "N/A"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingBottom: "5px",
                        }}
                      >
                        {" "}
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Invoice Payable Url
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ wordBreak: "break-all" }}
                        >
                          <a
                            target="_blank"
                            href={`${detailData?.invoicePayableUrl}`}
                          >
                            {detailData?.invoicePayableUrl?.length > 1
                              ? detailData?.invoicePayableUrl
                              : "N/A"}
                          </a>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default InvoiceList;

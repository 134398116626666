import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation, useParams } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import moment from "moment";
import axios from "axios";
import { Grid } from "@mui/material";
import ParentDetails from "./ParentDetails";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AddIcon from "@mui/icons-material/Add";
import AddParentNewKid from "./AddParentNewKid";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",

    width: "100%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:first-child": {
      padding: "10px 16px",
    },
    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& td": {
    //     paddingTop: "12px",
    //     paddingBottom: "12px",
    //     padding: "12px 6px",
    //   },
    //   "& td:nth-child(n+3)": {
    //     paddingLeft: "0px",
    //   },
    // },
  },
  gridStyle: {
    border: "1px solid #999999",
    padding: "20px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
}));

const KidListByParent = ({ rollPermission }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const classes = useStyles();
  const { slug } = useParams();
  const { state } = useLocation();
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteData({});
  };

  const handleDeleteDialog = (i, row) => {
    setDeleteData({ index: i, row: row });
    setDeleteDialog(true);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 7; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(7).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };
  const handleDelete = async () => {
    try {
      setLoading2(true);
      let formData = new FormData();

      formData.append("firstName", deleteData.row.firstName);
      formData.append("lastName", deleteData.row.lastName);
      formData.append("dateOfBirth", deleteData.row.dateOfBirth);
      formData.append("gender", deleteData.row.gender);
      formData.append("status", false);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${deleteData.row.kidId}`,
        method: "put",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Deleted successfully", "success");

        getData();
      }

      setDeleteDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      // handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading2(false);
      setDeleteDialog(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO) => {
    setLoading(true);
    let newPageNO = page;

    if (pageNO >= 0) {
      newPageNO = pageNO;
    }
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/parent/${slug}/kid`;
    let allData = await getDataWithToken(allDataUrl, token);

    if (allData.status >= 200 && allData.status < 300) {
      setTableDataList(allData?.data);
      // setRowsPerPage(allData?.data?.size);
      // setTotalData(allData?.data?.totalElements);

      if (allData.data.length < 1) {
        setMessage("No data found");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={classes.form}>
        <Grid container>
          <Grid item md={12} lg={4.9} xl={3.9} className={classes.gridStyle}>
            <ParentDetails />
          </Grid>
          <Grid item md={0} lg={0.2} xl={0.2}></Grid>
          <Grid item md={12} lg={6.9} xl={7.9}>
            <div
              style={{
                background: "#fff",
                border: "1px solid #ddd",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Grid
                container
                alignItems="center"
                style={{ background: "#ddd", padding: "10px 16px" }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="info"
                    gutterBottom
                    component="div"
                    style={{ margin: 0 }}
                  >
                    <TableChartIcon
                      style={{
                        position: "relative",
                        top: "4px",
                        fontSize: "20px",
                      }}
                    />{" "}
                    Assigned Kids
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {" "}
                  <IconButton
                    style={{
                      // position: "absolute",
                      // color: "#fff",
                      // right: 0,
                      // top: -5,
                      border: "1px solid #999999",
                    }}
                    onClick={() => setDialogOpen(true)}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <div
                style={{
                  overflowX: "auto",

                  minWidth: "100%",
                  // width: "Calc(100vw - 367px)",
                  padding: "10px 16px 0px",
                  boxSizing: "border-box",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="80px" align="center">
                        Image
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        First Name
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Last Name
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Date of Birth
                      </TableCell>

                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Created At
                      </TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      tableDataList.length > 0 &&
                      tableDataList.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            <img
                              src={
                                row?.photo.length > 0
                                  ? row?.photo
                                  : "/image/NoImage.jpg"
                              }
                              height="40px"
                              width="40px"
                              alt=""
                            />
                          </TableCell>
                          <TableCell>{row?.firstName}</TableCell>
                          <TableCell>{row?.lastName}</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {row?.dateOfBirth}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(row?.createdAt).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.status ? (
                              <TaskAltOutlinedIcon
                                style={{ color: "#10ac84" }}
                              />
                            ) : (
                              <HighlightOffOutlinedIcon
                                style={{ color: "#ee5253" }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              variant="contained"
                              // color="success"
                              disableElevation
                              component={Link}
                              to={`/kid-detail/${row?.kidId}`}
                              state={{ row }}
                            >
                              <VisibilityOutlinedIcon />
                            </IconButton>

                            {/* <IconButton
                              variant="contained"
                              disableElevation
                              onClick={() => handleDeleteDialog(i, row)}
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton> */}
                          </TableCell>
                        </TableRow>
                      ))}

                    {!loading && tableDataList.length < 1 ? (
                      <TableRow>
                        <TableCell colSpan={9} style={{ textAlign: "center" }}>
                          <strong> {message}</strong>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {loading && pageLoading()}
                  </TableBody>
                </Table>
              </div>
              <br />
              {/* {tableDataList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )} */}
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <div style={{ minWidth: "300px", padding: "30px 30px 0px" }}>
          <DialogContent style={{ padding: 0 }}>
            <AddParentNewKid
              getData={getData}
              handleDialogClose={handleDialogClose}
            />
          </DialogContent>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to delete {deleteData?.row?.firstName}{" "}
              {deleteData?.row?.lastName}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleDelete}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default KidListByParent;

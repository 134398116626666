import axios from "axios";

const getDataWithToken = async (url, token) => {
  try {
    let response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log("error", error);
    return error.response;
  }
};

export { getDataWithToken };

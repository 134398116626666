import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from "notistack";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { getDataWithToken } from "../../services/GetDataService";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
const useStyles = makeStyles((theme) => ({
  form: {
    // padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    // width: "1200px !important",
    // height: "100vh",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
}));

const Login = ({ reloadFromLogin, setReloadFromLogin }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [message, setMessage] = useState("");
  const [loginData, setLoginData] = useState({});
  const [childcarelist, setChildcareList] = useState([]);
  const [open, setOpen] = useState(false);
  const [chidlcareId, setChidlcareId] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setChidlcareId(event.target.value);
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const validation = () => {
    let isError = false;
    if (!email.trim()) {
      handleSnakbarOpen("Please enter email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email.trim()
      )
    ) {
      handleSnakbarOpen("Invalid email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    }

    if (!password.trim()) {
      handleSnakbarOpen("Please enter password", "error");
      document.getElementById("password").focus();
      return (isError = true);
    }
    return isError;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const getEmergencyCode = async (tokenAndRoles) => {
    
    try {
      const emergencyCodeUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare/emergency-code/admin`;
      let myEmergencyCode = await getDataWithToken(
        emergencyCodeUrl,
        tokenAndRoles.access_token
      );
    
      if (myEmergencyCode.status >= 200 && myEmergencyCode.status < 300) { 
        if (myEmergencyCode.data < 1) {
          setMessage("No data found");
        }
        return myEmergencyCode?.data;
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const getChildcare = async (tokenAndRoles) => {
    try {
      const allChildcareUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/businesses`;
      let allChildcare = await getDataWithToken(
        allChildcareUrl,
        tokenAndRoles.access_token
      );
      if (allChildcare.status >= 200 && allChildcare.status < 300) {
        setChildcareList(allChildcare?.data);

        if (allChildcare?.data.length === 1) {
          fnSetPrimaryDaycare(allChildcare?.data[0]?.id, tokenAndRoles);
        } else {
          setChidlcareId(allChildcare?.data[0]?.id);
          // setMyOpacity(0.5);
          setOpen(true);
        }
        if (allChildcare?.data.length < 1) {
          setMessage("No data found");
        }
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const fnSetPrimaryDaycare = async (id, tokenAndRoles) => {
    let newChildcareId = chidlcareId;
    if (id) {
      newChildcareId = id;
    }
    let newTokenAndRoles = tokenAndRoles;
    if (tokenAndRoles === undefined) {
      newTokenAndRoles = loginData;
    }

    setLoading2(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/businesses/${newChildcareId}/primary`,
        method: "put",
        headers: {
          Authorization: `Bearer ${newTokenAndRoles.access_token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const myEmergencyCode = await getEmergencyCode(newTokenAndRoles);
        login({
          ...newTokenAndRoles,
          primaryDaycareId: newChildcareId,
          myEmergencyCode: myEmergencyCode,
        });
        handleSnakbarOpen("Login successfully", "success");
        setReloadFromLogin(!reloadFromLogin);
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.errors.toString(), "error");
      setLoading2(false);
    }
    setLoading2(false);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let data = {
          grant_type: "password",
          client_id: process.env.REACT_APP_Client_Id,
          client_secret: process.env.REACT_APP_Client_Secret,
          email: email.trim(),
          password: password.trim(),
          scope: "openid profile",
        };
        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/public/auth/signin`,
          method: "post",
          data: data,
        });

        let token = response.data.data.access_token;

        const rolesurl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/user/roles`;
        let roles = await getDataWithToken(rolesurl, token);

        if (roles.status >= 200 && roles.status < 300) {
          if (roles?.data?.length > 0) {
            let tokenAndRoles = {
              ...response.data.data,
              roles: roles?.data,
            };
            setLoginData(tokenAndRoles);

            // handleSnakbarOpen(response.data.message.toString(), "success");
            // login({ ...response.data.data, roles: roles?.data });
            roles?.data?.map((e) => {
              switch (e.name) {
                case "SUPER_ADMIN":
                  setReloadFromLogin(!reloadFromLogin);
                  login(tokenAndRoles);
                  navigate("/admin-dashboard");
                  break;
                case "DAYCARE_ADMIN":
                  getChildcare(tokenAndRoles);
                  // getEmergencyCode(tokenAndRoles);
                  // navigate("/childcare-list");
                  // navigate("/dashboard");
                  break;
              }
            });
          } else {
            handleSnakbarOpen("Unauthorized user", "error");
          }
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errors.toString(), "error");
        setLoading(false);
      }
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      {/* <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      > */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.form}
      >
        <Grid
          item
          xs={6}
          style={{
            background: "bisque",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <Grid container justifyContent="center" alignItems="center"> */}
          <div>
            <img src="/image/login.png" alt="" style={{ maxWidth: "70%" }} />
          </div>
          {/* </Grid> */}
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={onSubmit} style={{ maxWidth: "60%", margin: "auto" }}>
            <img
              src="/image/logo.png"
              alt=""
              style={{ display: "block", margin: "auto", maxWidth: "155px" }}
            />
            <br />
            {open === false ? (
              <div>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ marginBottom: "30px" }}
                >
                  Sign-In to continue{" "}
                </Typography>
                <TextField
                  autoFocus
                  placeholder="Enter your email address"
                  fullWidth
                  size="small"
                  className={classes.inputStyle}
                  style={{ marginBottom: "30px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: "30px" }}
                >
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  style={{ marginBottom: "30px", minHeight: "37px" }}
                  disabled={loading}
                  type="submit"
                >
                  {loading === false && "Continue"}
                  <PulseLoader
                    color={"#353b48"}
                    loading={loading}
                    size={10}
                    speedMultiplier={0.5}
                  />{" "}
                </Button>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Remember me"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      style={{
                        color: "#F91351",
                        textAlign: "right",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password?
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ marginBottom: "30px" }}
                >
                  Please select a childcare
                </Typography>

                <FormControl style={{ width: "100%", textAlign: "left" }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={chidlcareId}
                    onChange={handleChange}
                  >
                    {childcarelist?.map((item, i) => (
                      <FormControlLabel
                        style={{
                          border: "1px solid #7c7c7c",
                          padding: "10px",
                          // width: "350px",
                          margin: "0 0 20px",
                          borderRadius: "10px",
                        }}
                        key={i}
                        value={item.id}
                        control={<Radio />}
                        label={
                          <div>
                            <b>{item.name}</b> <br />
                            <span style={{ color: "#7c7c7c" }}>
                              {item?.city}-{item?.state}-{item?.country}
                            </span>
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={loading2}
                  onClick={() => {
                    fnSetPrimaryDaycare();
                  }}
                  style={{ minHeight: "37px", marginTop: "10px" }}
                >
                  {loading2 === false && "Continue"}
                  <PulseLoader
                    color={"#353b48"}
                    loading={loading2}
                    size={10}
                    speedMultiplier={0.5}
                  />{" "}
                </Button>
              </div>
            )}
          </form>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </React.Fragment>
  );
};

export default Login;

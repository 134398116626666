import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import TextField from "@mui/material/TextField";
import axios from "axios";
import RefreshToken from "../../services/RefreshToken";
import { useNavigate } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    width: "900px",
    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "900px",
    },
  },
  input: {
    display: "none",
  },
  genderTitle: {
    fontSize: "18px !important",
    margin: "0 0 5px",
  },
}));

const SendNotification = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const validation = () => {
    let isError = false;

    if (!title.trim()) {
      handleSnakbarOpen("Please enter title", "error");
      document.getElementById("title").focus();
      return (isError = true);
    }
    if (title.trim().length > 80) {
      handleSnakbarOpen("Title can not exceed 80 characters", "error");
      document.getElementById("title").focus();
      return (isError = true);
    }
    if (!message.trim()) {
      handleSnakbarOpen("Please enter message", "error");
      document.getElementById("message").focus();
      return (isError = true);
    }
    if (message.trim().length > 255) {
      handleSnakbarOpen("Message title can not exceed 80 characters", "error");
      document.getElementById("message").focus();
      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        // let formData = new FormData();

        // formData.append("title", title);
        // formData.append("message", message);

        let data = {
          title: title,
          subTitle: message,
        };
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/broadcast/push-notification`,
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Notification sent successfully", "success");
          setTitle("");
          setMessage("");
          //   navigate("/book-list");
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "calc(100vh - 120px)" }}
      >
        <form className={classes.form} onSubmit={onSubmit}>
          <Typography variant="h4" component="div" style={{ fontWeight: 500 }}>
            <CampaignIcon
              style={{
                fontSize: "42px",
                color: "#f26522",
                position: "relative",
                top: 7,
              }}
            />{" "}
            Broadcast Notification
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            style={{ color: "#767676" }}
          >
            This feature allows you to notify all parents of your daycare at
            once who are using KinderCubby Mobile App
          </Typography>
          <hr />
          <br />
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label="Enter your title"
                id="title"
                variant="outlined"
                size="small"
                fullWidth
                value={title}
                onChange={(e) => {
                  if (title.length < 80) {
                    setTitle(e.target.value);
                  } else {
                    setTitle(e.target.value.slice(0, 80));
                  }
                  //    else {
                  //     handleSnakbarOpen("Max 80 characters allowed", "error");
                  //   }
                }}
              />
              <p
                style={{
                  marginBottom: "30px",
                  marginTop: "7px",
                  textAlign: "right",
                  color: title.length < 80 ? "#767676" : "#ff1d1d",
                }}
              >
                {title.length}/80 characters
              </p>
              <TextField
                label="Enter your message"
                id="message"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={6}
                value={message}
                onChange={(e) => {
                  if (message.length < 255) {
                    setMessage(e.target.value);
                  } else {
                    setMessage(e.target.value.slice(0, 255));
                  }
                  //    else {
                  //     handleSnakbarOpen("Max 80 characters allowed", "error");
                  //   }
                }}
              />
              <p
                style={{
                  marginBottom: "30px",
                  marginTop: "7px",
                  textAlign: "right",
                  color: message.length < 255 ? "#767676" : "#ff1d1d",
                }}
              >
                {message.length}/255 characters
              </p>

              <Button
                fullWidth
                variant="contained"
                disableElevation
                disabled={loading}
                type="submit"
                style={{ minHeight: "37px" }}
              >
                {loading === false && "Notify All"}
                <PulseLoader
                  color={"#353b48"}
                  loading={loading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default SendNotification;

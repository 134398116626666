import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import moment from "moment";
import { getDataWithToken } from "../../../services/GetDataService";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import MyCamera from "../../../services/MyCamera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px",
    background: "#fff",
    borderRadius: "10px",
    width: "1000px",
    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "900px",
    },
  },
  input: {
    display: "none",
  },
  genderTitle: {
    fontSize: "18px !important",

    margin: "0 0 5px",
  },
}));

const UpdateKidInfo = ({
  kidInfo,
  setKidInfo,
  allergyStr,
  setAllergyStr,
  likeStr,
  setLikeStr,
  handleUpdateDialogClose,
}) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const classes = useStyles();
  const uploadImage = "/image/upload.png";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [allergies, setAllergies] = useState("");
  const [likes, setLikes] = useState("");
  const [status, setStatus] = useState(false);
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(uploadImage);
  const [openCamera, setOpenCamera] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };

  const validation = () => {
    let isError = false;
    if (!firstName.trim()) {
      handleSnakbarOpen("Please enter first name", "error");
      document.getElementById("firstName").focus();
      return (isError = true);
    }
    if (!lastName.trim()) {
      handleSnakbarOpen("Please enter last name", "error");
      document.getElementById("lastName").focus();
      return (isError = true);
    }
    if (dob === null) {
      handleSnakbarOpen("Please enter date of birth", "error");

      return (isError = true);
    }

    if (!gender.trim()) {
      handleSnakbarOpen("Please select gender", "error");
      document.getElementById("password").focus();
      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async (e) => { 
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("dateOfBirth", moment(dob).format("YYYY-MM-DD"));
        formData.append("gender", gender);
        formData.append("status", status);

        if (imageFile !== null) {
          formData.append("photo", imageFile);
        }
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidInfo?.kidId}`,
          method: "put",
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
       
        if (response.status >= 200 && response.status < 300) {
          let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
          let deleteLikes = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidInfo?.kidId}/likes`,
            method: "delete",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (deleteLikes.status >= 200 && deleteLikes.status < 300) {
            let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
            let likesArray = likes.split(",");
            likesArray.map((item) => {
              let like = new FormData();

              like.append("tag", item.trim());
              axios({
                url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidInfo?.kidId}/likes`,
                method: "post",
                data: like,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            });
          }
    
          let deleteAllergy = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidInfo?.kidId}/allergies`,
            method: "delete",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (deleteAllergy.status >= 200 && deleteAllergy.status < 300) {
            let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
            let allergiesArray = allergies.split(",");
            allergiesArray.map((item) => {
              let alg = new FormData();
              alg.append("tag", item.trim());
              axios({
                url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidInfo?.kidId}/allergies`,
                method: "post",
                data: alg,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            });
          }

          setAllergyStr(allergies);
          setLikeStr(likes);
         
          const kidUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidInfo?.kidId}`;
          let kidRes = await getDataWithToken(kidUrl, token);
          if (kidRes.status >= 200 && kidRes.status < 300) {
            setKidInfo(kidRes.data);
          }
          handleUpdateDialogClose();
          handleSnakbarOpen("Updated successfully", "success");
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error); 
        setLoading(false);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    setFirstName(kidInfo?.firstName);
    setLastName(kidInfo?.lastName);
    setDob(kidInfo?.dateOfBirth);
    setGender(kidInfo?.gender);
    setStatus(kidInfo?.status);
    setPreview(kidInfo?.photo);

    setAllergies(allergyStr);
    setLikes(likeStr);
  }, []);

  return (
    <div>
      <form className={classes.form} onSubmit={onSubmit}>
        <div>
          <div style={{ textAlign: "center" }}>
            <img
              src={preview}
              alt=""
              style={{
                height: "120px",
                width: "120px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() =>
                document.getElementById("contained-button-file").click()
              }
            />
            <Button
              size="small"
              variant="outlined"
              style={{ marginTop: "5px", width: "140px", padding: 4  }}
              onClick={() =>
                document.getElementById("contained-button-file").click()
              }
              startIcon={<FileUploadOutlinedIcon />}
            >
              Upload
            </Button>
            &nbsp;
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginTop: "5px" }}
                  onClick={() => setOpenCamera(true)}
                >
                  <CameraAltOutlinedIcon />
                </Button>
          </div>

          <input
            accept="image/png, image/jpg, image/jpeg"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={imageProcess}
          />
        </div>
        <br />

        <Grid container columnSpacing={8}>
          <Grid item xs={6}>
            <Typography variant="h5" component="div">
              Update Kid Information
            </Typography>
            <hr />
            <br />

            <TextField
              style={{ marginBottom: "30px" }}
              label="First Name"
              id="firstName"
              variant="outlined"
              size="small"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <TextField
              style={{ marginBottom: "30px" }}
              label="Last Name"
              id="lastName"
              variant="outlined"
              size="small"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="yyyy-MM-dd"
                label="Date of Birth"
                value={dob}
                onChange={(newValue) => {
                  setDob(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    style={{ marginBottom: "30px" }}
                  />
                )}
              />
            </LocalizationProvider>

            <Grid container alignItems="center">
              <Grid item xs={3}>
                <p
                  className={classes.genderTitle}
                  component="div"
                  // style={{ marginBottom: "30px" }}
                >
                  Gender :
                </p>
              </Grid>
              <Grid item xs={9}>
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "space-between" }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="Not_Applicable"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <p
                  className={classes.genderTitle}
                  component="div"
                  // style={{ marginBottom: "30px" }}
                >
                  Status :
                </p>
              </Grid>
              <Grid item xs={9}>
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <FormControlLabel
                      style={{ marginRight: "90px" }}
                      value={true}
                      control={<Radio />}
                      label="Active"
                    />

                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Inactive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" component="div">
              Additional Information (if any)
            </Typography>
            <hr />
            <br />
            <TextField
              style={{ marginBottom: "30px" }}
              label="Allergies (if any)"
              id="allergies"
              variant="outlined"
              size="small"
              fullWidth
              value={allergies}
              onChange={(e) => setAllergies(e.target.value)}
            />
            <TextField
              style={{ marginBottom: "30px" }}
              label="Likes"
              id="likes"
              variant="outlined"
              size="small"
              fullWidth
              value={likes}
              onChange={(e) => setLikes(e.target.value)}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "50px",
            textAlign: "right",
          }}
        >
          <Button
            variant="text"
            style={{ marginRight: "10px" }}
            onClick={handleUpdateDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: "150px",
            }}
            disabled={loading}
            type="submit"
          >
            {loading === false && "Continue"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </div>
      </form>
      <MyCamera
        setImageFile={setImageFile}
        setPreview={setPreview}
        openCamera={openCamera}
        setOpenCamera={setOpenCamera}
      />
    </div>
  );
};

export default UpdateKidInfo;

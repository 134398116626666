import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import UpdateKidInfo from "./UpdateKidInfo"; 
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";

const KidInformation = () => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const { state } = useLocation();
  const { slug } = useParams();
  const [kidInfo, setKidInfo] = useState({});
  const [allergyStr, setAllergyStr] = useState("");
  const [likeStr, setLikeStr] = useState("");
  const [likeMessage, setLikeMessage] = useState("");
  const [allergyMessage, setAllergyMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [updateDialog, setUpdateDialog] = useState(false);
  const handleUpdateDialogClose = () => {
    setUpdateDialog(false);
  };

  const getData = async () => {
    setLoading(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const kidUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}`;
    let kidData = await getDataWithToken(kidUrl,token);
    setKidInfo(kidData?.data);
    const likesUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/likes`;
    let allLike = await getDataWithToken(likesUrl,token);

    if (allLike.status >= 200 && allLike.status < 300) {
      let likesData = [];
      {
        allLike.data?.map((item, i) => {
          likesData.push(item.tag);
        });
      }

      let newStr = likesData.join(", ").replace(/,\s*$/, "");

      setLikeStr(newStr);

      if (allLike.data.length < 1) {
        setLikeMessage("No data found");
      }
    }
    const allergyUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/allergies`;
    let allAllergy = await getDataWithToken(allergyUrl,token);

    if (allAllergy.status >= 200 && allAllergy.status < 300) {
      let allergyData = [];
      {
        allAllergy.data?.map((item, i) => {
          allergyData.push(item.tag);
        });
      }
      let newStr = allergyData.join(", ").replace(/,\s*$/, "");

      setAllergyStr(newStr);

      if (allAllergy.data.length < 1) {
        setAllergyMessage("No data found");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {" "}
      <Typography variant="h6" component="div" style={{ position: "relative",fontSize: "18px" }}>
        Kid Information
        <IconButton 
          onClick={() => setUpdateDialog(true)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Typography>
      <hr />
      <br />
      <div style={{ textAlign: "center",marginBottom: "15px" }}>
        {loading ? (
          <>
            <div style={{ margin: "auto", width: "70px" }}>
              <Skeleton variant="circular" width={70} height={70} />
            </div>
            <div style={{ margin: "auto", width: "210px" }}>
              <Skeleton variant="rectangular" width={210} />
            </div>
            
          </>
        ) : (
          <>
            <img
              src={
                kidInfo?.photo.length > 0
                  ? kidInfo?.photo
                  : "/image/NoImage.jpg"
              }
              alt=""
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
              }}
            />
            <Typography variant="h6">
              {" "}
              {kidInfo?.firstName} {kidInfo?.lastName}
            </Typography>

            
          </>
        )}
      </div>
      <div>
        {loading ? (
          <Skeleton variant="rectangular" height={150} />
        ) : (
          <Grid container style={{ padding: "0px 20px" }}>
            <Grid
              item
              xs={6}
              style={{
                paddingBottom: "5px",
              }}
            >
              {" "}
              <Typography
                variant="caption"
                style={{
                  color: "#808080",
                }}
              >
                Date of Birth
              </Typography>
              <Typography variant="body2">{kidInfo?.dateOfBirth}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: "right",
                paddingBottom: "5px",
              }}
            >
              {" "}
              <Typography
                variant="caption"
                style={{
                  color: "#808080",
                }}
              >
                Gender
              </Typography>
              <Typography variant="body2">{kidInfo?.gender}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingBottom: "5px",
              }}
            >
              {" "}
              <Typography
                variant="caption"
                style={{
                  color: "#808080",
                }}
              >
                Allergies
              </Typography>
              <Typography variant="body2">
                {allergyStr.length > 0 ? allergyStr : "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: "right",
                paddingBottom: "5px",
              }}
            >
              {" "}
              <Typography
                variant="caption"
                style={{
                  color: "#808080",
                }}
              >
                Likes
              </Typography>
              <Typography variant="body2">
                {likeStr.length > 0 ? likeStr : "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingBottom: "5px",
              }}
            >
              {" "}
              <Typography
                variant="caption"
                style={{
                  color: "#808080",
                }}
              >
                Status
              </Typography>
              <Typography variant="body2">
                {kidInfo?.status === true ? (
                  <span style={{ color: "#10ac84" }}>
                    <b>Active</b>
                  </span>
                ) : (
                  <span style={{ color: "#ee5253" }}>
                    <b>Inactive</b>
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: "right",
                paddingBottom: "5px",
              }}
            >
              {" "}
              <Typography
                variant="caption"
                style={{
                  color: "#808080",
                }}
              >
                Created At
              </Typography>
              <Typography variant="body2">
                {moment(kidInfo?.createdAt).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
      <Dialog
        open={updateDialog}
        onClose={handleUpdateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogContent>
            <UpdateKidInfo
              kidInfo={kidInfo}
              setKidInfo={setKidInfo}
              allergyStr={allergyStr}
              setAllergyStr={setAllergyStr}
              likeStr={likeStr}
              setLikeStr={setLikeStr}
              handleUpdateDialogClose={handleUpdateDialogClose}
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default KidInformation;

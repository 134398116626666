import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import axios from "axios";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Map from "../../map/Map";
import ClearIcon from "@mui/icons-material/Clear";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import RefreshToken from "../../services/RefreshToken";
import { AuthContext } from "../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px 50px",
    background: "#fff",
    borderRadius: "10px",

    // width: "1000px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
  },
  input: {
    display: "none",
  },
  gridStyle: {
    border: "1px solid #999999",
    padding: "10px 20px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
}));
const ChildCareList = () => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const classes = useStyles();
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [detailDialog, setDetailDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [detailData, setDetailData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleDetailDialogClose = () => {
    setDetailDialog(false);
    setDeleteData({});
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteData({});
  };

  const handleDeleteDialog = (i, row) => {
    setDeleteData({ index: i, row: row });
    setDeleteDialog(true);
  };

  const pageLoading = () => {
    let content = [];
    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(10).keys()].map(() => (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };
  const handleDelete = async () => {
    try {
      setLoading2(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare/${deleteData.row.id}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Deleted successfully", "success");
        getData();
      }
      setDeleteDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      setLoading2(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setDeleteDialog(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO) => {
    try {
      setLoading(true);
      let newPageNO = page;
      if (pageNO >= 0) {
        newPageNO = pageNO;
      }
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare?page=${newPageNO}`;
      let allData = await getDataWithToken(allDataUrl, token);
      if (allData.status >= 200 && allData.status < 300) {
        setTableDataList(allData?.data?.content);
        setRowsPerPage(allData?.data?.size);
        setTotalData(allData?.data?.totalElements);

        if (allData.data.content.length < 1) {
          setMessage("No data found");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const getDataById = async (id) => {
    try {
      setLoading3(true);
      setDetailDialog(true);
      const childCareDateUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare/${id}`;
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let childCareDate = await getDataWithToken(childCareDateUrl, token);

      if (childCareDate.status >= 200 && childCareDate.status < 300) {
        setDetailData(childCareDate?.data);
      }
      setLoading3(false);
    } catch (error) {
      console.log("error", error);
      setLoading3(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          color="info"
          gutterBottom
          component="div"
          style={{ background: "#ddd", padding: "10px 16px" }}
        >
          <TableChartIcon
            style={{ position: "relative", top: "4px", fontSize: "20px" }}
          />{" "}
          Child Care List
        </Typography>

        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 367px)",
            padding: "10px 16px 0px",
            boxSizing: "border-box",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="80px" align="center">
                  Image
                </TableCell>
                <TableCell>Name</TableCell>

                <TableCell>Address</TableCell>
                <TableCell>Street</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Zip Code</TableCell>
                <TableCell>Country</TableCell>
                {/* <TableCell style={{ whiteSpace: "nowrap" }}>
                  Contact Person Name
                </TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Contact Person Phone
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Contact Person Email
                </TableCell> */}
                <TableCell align="center">Status</TableCell>
                <TableCell align="center" style={{ minWidth: "160px" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tableDataList.length > 0 &&
                tableDataList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      <img
                        src={
                          row?.logo?.length > 0
                            ? row?.logo
                            : "/image/NoImage.jpg"
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>{row?.address}</TableCell>
                    <TableCell>{row?.street}</TableCell>
                    <TableCell>{row?.city}</TableCell>
                    <TableCell>{row?.state}</TableCell>
                    <TableCell>{row?.postalCode}</TableCell>
                    <TableCell>{row?.country}</TableCell>
                    {/* <TableCell>{row?.contactPersonName}</TableCell>
                    <TableCell>{row?.contactPersonPhone}</TableCell>
                    <TableCell>{row?.contactPersonEmail}</TableCell> */}

                    <TableCell align="center">
                      {row?.status ? (
                        <TaskAltOutlinedIcon style={{ color: "#10ac84" }} />
                      ) : (
                        <HighlightOffOutlinedIcon
                          style={{ color: "#ee5253" }}
                        />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        variant="contained"
                        // color="success"
                        disableElevation
                        onClick={() => getDataById(row?.id)}
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        color="success"
                        disableElevation
                        component={Link}
                        to={`/update-child-care`}
                        state={{ row }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>

                      <IconButton
                        variant="contained"
                        disableElevation
                        onClick={() => handleDeleteDialog(i, row)}
                      >
                        <DeleteOutlineIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && tableDataList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={10} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {tableDataList.length > 0 ? (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <br />
        )}
      </div>
      <Dialog
        open={detailDialog}
        onClose={handleDetailDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <div
          style={{ padding: "10px", minWidth: "1100px", position: "relative" }}
        >
          <IconButton
            onClick={handleDetailDialogClose}
            style={{ position: "absolute", right: 17 }}
          >
            <ClearIcon />
          </IconButton>
          <DialogContent>
            <div style={{ textAlign: "center" }}>
              {loading3 ? (
                <>
                  <div style={{ margin: "auto", width: "90px" }}>
                    <Skeleton variant="circular" width={90} height={90} />
                  </div>
                  <div style={{ margin: "auto", width: "210px" }}>
                    <Skeleton variant="rectangular" width={210} />
                  </div>
                  <div style={{ margin: "auto", width: "150px" }}>
                    <Skeleton variant="rectangular" width={150} height={10} />
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={detailData?.logo}
                    alt=""
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography variant="h6">{detailData?.name} </Typography>

                  {detailData?.status ? (
                    <span
                      style={{
                        color: "#10ac84",
                        fontSize: "18px",
                      }}
                    >
                      <TaskAltOutlinedIcon
                        style={{
                          fontSize: "18px",
                          position: "relative",
                          top: "2px",
                          left: "-3px",
                        }}
                      />{" "}
                      Active
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#ee5253",
                        fontSize: "18px",
                      }}
                    >
                      <HighlightOffOutlinedIcon
                        style={{
                          fontSize: "18px",
                          position: "relative",
                          top: "2px",
                          left: "-3px",
                        }}
                      />
                      Inactive
                    </span>
                  )}
                </>
              )}
            </div>
            <br />
            <Grid container>
              <Grid item xs={5.9} className={classes.gridStyle}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: 400 }}
                >
                  <AccountCircleOutlinedIcon
                    style={{
                      color: "#8b8b8b",
                      position: "relative",
                      top: "6px",
                      fontSize: "28px",
                    }}
                  />{" "}
                  Contact Information
                </Typography>
                <hr />
                {loading3 ? (
                  <>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.contactPersonName}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Phone
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.contactPersonPhone}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography variant="body2">
                      {detailData?.contactPersonEmail}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={0.2}></Grid>
              <Grid item xs={5.9} className={classes.gridStyle}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: 400 }}
                >
                  <LocationOnOutlinedIcon
                    style={{
                      color: "#8b8b8b",
                      position: "relative",
                      top: "6px",
                      fontSize: "28px",
                    }}
                  />{" "}
                  Address
                </Typography>
                <hr />
                {loading3 ? (
                  <>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                    <Skeleton variant="rectangular"></Skeleton>
                  </>
                ) : (
                  <>
                    <Grid container rowSpacing={0.5}>
                      <Grid item xs={6}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Country
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.country}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          State
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.state}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          City
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.city}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Street
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.street}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Address
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.address}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Zip Code
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.postalCode}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Latitude
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.latitude}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#808080",
                          }}
                        >
                          Longitude
                        </Typography>
                        <Typography variant="body2">
                          {detailData?.longitude}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <br />
            <div>
              {loading3 ? (
                <Skeleton variant="rectangular" height={300}></Skeleton>
              ) : (
                <Map
                  showSearch={false}
                  marker={{
                    lat: detailData?.latitude,
                    lng: detailData?.longitude,
                  }}
                  center={{
                    lat: detailData?.latitude,
                    lng: detailData?.longitude,
                  }}
                />
              )}
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleDetailDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleDelete}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions> */}
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to delete {deleteData?.row?.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleDelete}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ChildCareList;

import { decodedToken, isExpired } from "react-jwt";
import axios from "axios";

const RefreshToken = (kinder_cubby_panel_user, logout, login) => {
  let roles = kinder_cubby_panel_user.roles;
  let token = kinder_cubby_panel_user.access_token;
  const isMyTokenExpired = isExpired(token);

  // console.log("isMyTokenExpired", isMyTokenExpired);

  const refresh = async () => {
    try {
      if (!isMyTokenExpired) {
        // console.log("valid access token: ");
        return token;
      } else {

      let refreshTokenData = {
        grant_type: "refresh_token",
        client_id: `${process.env.REACT_APP_Client_Id}`,
        client_secret: process.env.REACT_APP_Client_Secret,
        refresh_token: kinder_cubby_panel_user.refresh_token,
      };
      let res = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/public/auth/refresh-token`,
        method: "POST",
        data: refreshTokenData,
        
      });
      //  console.log("new access token", res.data.data);
      login({ ...res.data.data, roles: roles });
      return res.data.data.access_token;
      }
    } catch (error) {
      console.log("error", error);
     
      if (error.response.status >= 400 && error.response.status <= 500) {
        logout();
      }
    }
  };
  return refresh();
};

export default RefreshToken;

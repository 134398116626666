import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { monthList } from "../../data/MonthList";
import { Grid } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../context/AuthContext";
import RefreshToken from "../../services/RefreshToken";
const MonthlyAttendenceReport = ({ rollPermission }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [tableDataList, setTableDataList] = useState([]);
  const [month, setMonth] = useState("");
  const [yearList, setYearList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(5).keys()].map(() => (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };
  const handleSearch = () => {
    let newDate = `${year}-${month}-01`;
    getData(newDate);
  };
  const clearFilter = () => {
    let presentYear = new Date().getFullYear();

    setYear(presentYear);

    let presentMonth = new Date().getMonth() + 1;
    let monthNumber = presentMonth;

    if (presentMonth < 10) {
      monthNumber = `0${presentMonth}`;
    }
    setMonth(monthNumber);
    getData();
  };
  const getData = async (date) => {
    try {
      setLoading(true);
      let presentYear = new Date().getFullYear();
      let presentMonth = new Date().getMonth() + 1;
      if (presentMonth < 10) {
        presentMonth = `0${presentMonth}`;
      }
      let presentDate = `${presentYear}-${presentMonth}-01`;
      if (date) {
        presentDate = date;
      }
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/attendance/report/monthly/${presentDate}`;
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        setTableDataList(allData?.data);

        if (allData.data.length < 1) {
          setMessage("No data found");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const getPresentMonth = () => {
    let newYearList = [];

    for (let index = 0; index <= 50; index++) {
      let startYear = 2000;
      newYearList.push(startYear + index);
    }
    setYearList(newYearList);
    let presentMonth = new Date().getMonth() + 1;
    let monthNumber = presentMonth;

    if (presentMonth < 10) {
      monthNumber = `0${presentMonth}`;
    }
    setMonth(monthNumber);
  };
  useEffect(() => {
    getPresentMonth();
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          color="info"
          gutterBottom
          component="div"
          style={{ background: "#ddd", padding: "10px 16px" }}
        >
          <TableChartIcon
            style={{ position: "relative", top: "4px", fontSize: "20px" }}
          />{" "}
          Monthly Attendence Report
        </Typography>

        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            padding: "10px 16px 0px",
            boxSizing: "border-box",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xs={4.5}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={month}
                  label="Month"
                  onChange={(e) => setMonth(e.target.value)}
                >
                  {monthList?.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4.5}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  {yearList?.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={3}>
                <Grid item md={4} lg={3.5} xl={3}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item md={8} lg={8.5} xl={9}>
                  <Button
                    variant="contained"
                    disableElevation
                    // color="success"
                    style={{ background: "#F26522", padding: "6.7px 22px" }}
                    size="large"
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={(event) => handleSearch()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Total Days Spent In TheMonth</TableCell>
                <TableCell>Total Time Spent In TheMonth</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tableDataList.length > 0 &&
                tableDataList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row?.firstName}</TableCell>
                    <TableCell>{row?.lastName}</TableCell>
                    <TableCell>{row?.month}</TableCell>
                    <TableCell>{row?.totalDaysSpentInTheMonth}</TableCell>
                    <TableCell>{row?.totalTimeSpentInTheMonth}</TableCell>
                  </TableRow>
                ))}

              {loading && pageLoading()}
            </TableBody>
          </Table>

          {!loading && tableDataList.length < 1 ? (
            <div
              style={{ textAlign: "center", fontWeight: 600, padding: "20px" }}
            >
              {message}
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
    </>
  );
};

export default MonthlyAttendenceReport;

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useLocation, useParams } from "react-router-dom";
import RefreshToken from "../../services/RefreshToken";
import { AuthContext } from "../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from "notistack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import UpdateTeacher from "./UpdateTeacher";
import { makeStyles } from "@mui/styles";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    margin: 0,
    fontSize: "24px !important",
    lineHeight: 1.334,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      fontSize: "20px !important",
    },
  },
  cardSubtitle: {
    margin: 0,
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
  cardImgHolder: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "120px",
    width: "120px",
    [theme.breakpoints.down("xl")]: {
      height: "90px",
      width: "90px",
    },
  },
  cardImg: {
    height: "100%",
    width: "100%",
    border: "1px solid #ddd",
    padding: "5px",
    borderRadius: "10px",
  },
  rowStyle: {
    borderBottom: "1px solid #ddd",
    padding: "5px 0px !important",
  },
}));

const TeacherDetails = () => {
  const { slug } = useParams();
  const classes = useStyles();
  const [teacherDetails, setTeacherDetails] = useState({});
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleUpdateDialogClose = () => {
    setDialogOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      setMessage("");
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let updateurl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/teacher/${slug}`;
      let teacherData = await getDataWithToken(updateurl, token);
      if (teacherData.status >= 200 && teacherData.status < 300) {
        setTeacherDetails(teacherData?.data);
      } else {
        setMessage(teacherData.data.messages.toString());
        if (teacherData.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {" "}
      <Typography
        className={classes.cardTitle}
        // variant="h5"
        component="div"
      >
        Teacher Information
        <IconButton
          style={{
            position: "absolute",
            color: "#999999",
            right: 0,
            top: -5,
            border: "1px solid #999999",
          }}
          onClick={() => setDialogOpen(true)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Typography>
      <hr />
      <br />
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "250px", background: "#f5f5f5" }}
        >
          <Typography variant="h5" component="div" style={{ color: "#999999" }}>
            {message}
          </Typography>
        </Grid>
      ) : (
        <>
          {/* Old design start ------------------------ */}
          {/* <Grid container>
          <Grid item xs={8}>
            {loading ? (
              <Skeleton variant="rectangular" height={300} />
            ) : (
              <Grid container>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    First Name
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    Last Name
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    Phone No
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.attributes?.phoneNumber[0]}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.email}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    Street
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.attributes?.street[0]}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    City
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.attributes?.locality[0]}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    State
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.attributes?.region[0]}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    Zip Code
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}

                    // style={{ marginBottom: "30px" }}
                  >
                    : {teacherDetails?.attributes?.postalCode[0]}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.rowStyle}>
                  {" "}
                  <Typography
                    className={classes.cardSubtitle}
                    style={{
                      color: "#808080",
                    }}
                  >
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.rowStyle}>
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    : {teacherDetails?.attributes?.country[0]}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    color: "#808080",
                    padding: "5px 0px",
                  }}
                >
                  {" "}
                  <Typography className={classes.cardSubtitle}>
                    Status{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    padding: "5px 0px",
                  }}
                >
                  <Typography className={classes.cardSubtitle}>
                    :{" "}
                    {teacherDetails?.enabled ? (
                      <span style={{ color: "#10ac84" }}>
                        <b>Active</b>
                      </span>
                    ) : (
                      <span style={{ color: "#ee5253" }}>
                        <b>Inactive</b>
                      </span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4}>
            <div className={classes.cardImgHolder}>
              {loading ? (
                <Skeleton variant="rectangular" width={120} height={120} />
              ) : (
                <img
                  src={
                    teacherDetails?.attributes?.photo?.length > 0
                      ? teacherDetails?.attributes?.photo[0]
                      : "/image/NoImage.jpg"
                  }
                  alt=""
                  className={classes.cardImg}
                />
              )}
            </div>
          </Grid>
        </Grid>  */}
          {/* Old design end ------------------------ */}
          {/* new design start ------------------------ */}
          <>
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
              {loading ? (
                <>
                  <div style={{ margin: "auto", width: "70px" }}>
                    <Skeleton variant="circular" width={70} height={70} />
                  </div>
                  <div style={{ margin: "auto", width: "210px" }}>
                    <Skeleton variant="rectangular" width={210} />
                  </div>
                  <div style={{ margin: "auto", width: "250px" }}>
                    <Skeleton variant="rectangular" width={250} height={10} />
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={
                      teacherDetails?.attributes?.photo?.length > 0
                        ? teacherDetails?.attributes?.photo[0]
                        : "/image/NoImage.jpg"
                    }
                    alt=""
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography variant="h6">
                    {" "}
                    {teacherDetails?.firstName} {teacherDetails?.lastName}
                  </Typography>

                  <div
                    style={{
                      color: "#999999",
                      fontSize: "18px",
                      marginTop: "-6px",
                    }}
                  >
                    <EmailOutlinedIcon
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "3px",
                        left: "-3px",
                      }}
                    />{" "}
                    {teacherDetails?.email}
                  </div>
                  {/* <div
                    style={{
                      color: "#999999",
                      fontSize: "18px",
                      // marginTop: "-6px",
                    }}
                  > */}
                  {teacherDetails?.enabled ? (
                    <div style={{ color: "#10ac84", fontSize: "18px" }}>
                      <TaskAltOutlinedIcon
                        style={{
                          position: "relative",
                          top: 4,
                          fontSize: "18px",
                        }}
                      />{" "}
                      <span>Active</span>
                    </div>
                  ) : (
                    <div style={{ color: "#ee5253", fontSize: "18px" }}>
                      <HighlightOffOutlinedIcon
                        style={{
                          position: "relative",
                          top: 4,
                          fontSize: "18px",
                        }}
                      />{" "}
                      <span>Inactive</span>
                    </div>
                  )}
                  {/* </div> */}
                </>
              )}
            </div>
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton variant="rectangular" height={150} />
                ) : (
                  <Grid container style={{ padding: "0px 20px" }}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Phone No
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.phoneNumber[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.street[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        City
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.locality[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        State
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.region[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.postalCode[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Country
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.country[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
          {/* new design end ------------------------ */}
        </>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleUpdateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <div style={{ minWidth: "300px" }}>
          <DialogContent style={{ padding: 0 }}>
            <UpdateTeacher
              routeToTeacherList={false}
              // state={state}
              getData={getData}
              handleUpdateDialogClose={handleUpdateDialogClose}
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default TeacherDetails;

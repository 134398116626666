import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../../services/GetDataService";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import RefreshToken from "../../../../services/RefreshToken";
import { AuthContext } from "../../../../context/AuthContext";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { monthList } from "../../../../data/MonthList";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import TotalBill from "./TotalBill";
import HourlyRates from "./HourlyRates";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const Billing = () => {
  const { slug } = useParams();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [message, setMessage] = useState("");
  const [yearList, setYearList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [totalViewShow, setTotalViewShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [monthlyAttendenceReport, setMonthlyAttendenceReport] = useState({});
  const [open, setOpen] = React.useState(false);
  const [tableDataList, setTableDataList] = useState([]);
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 5; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(5).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const clearFilter = () => {
    let presentYear = new Date().getFullYear();

    setYear(presentYear);

    let presentMonth = new Date().getMonth() + 1;
    let monthNumber = presentMonth;

    if (presentMonth < 10) {
      monthNumber = `0${presentMonth}`;
    }
    setMonth(monthNumber);
    getData();
  };
  const handleSearch = () => {
    let newDate = `${year}-${month}-01`;

    getData(newDate);
  };
  const getData = async (date) => {
    try {
      setLoading(true);

      let presentYear = new Date().getFullYear();
      let presentMonth = new Date().getMonth() + 1;

      if (presentMonth < 10) {
        presentMonth = `0${presentMonth}`;
      }

      let presentDate = `${presentYear}-${presentMonth}-01`;

      if (date) {
        presentDate = date;
      }
      const kidMontlyReportUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/attendance/report/monthly/${presentDate}`;
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let kidMontlyReport = await getDataWithToken(kidMontlyReportUrl, token);

      if (kidMontlyReport.status >= 200 && kidMontlyReport.status < 300) {
        setMonthlyAttendenceReport(kidMontlyReport?.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const generateInvoice = async () => {
    try {
      setInvoiceLoading(true);

      let date = `${year}-${month}-01`;

      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let Invoice = await await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/billing/${date}`,
        method: "put",
        data: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (Invoice.status >= 200 && Invoice.status < 300) {
        if (Invoice.data) {
          handleSnakbarOpen("Invoice Generated Successfully", "success");
        } else {
          handleSnakbarOpen("Invoice Generation Failed", "error");
        }
      }
      setInvoiceLoading(false);
    } catch (error) {
      console.log("error", error);
      setInvoiceLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };

  const getAllInvoice = async () => {
    setInvoiceLoading(true);
    try {
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/invoice`;
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        if (allData.data.length < 1) {
          setMessage("No data found");
        } else {
          setTableDataList(allData?.data.reverse());
        }
        handleClickOpen();
      }
      setInvoiceLoading(false);
    } catch (error) {
      console.log("error", error);
      setInvoiceLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const getPresentMonth = () => {
    let newYearList = [];

    for (let index = 0; index <= 50; index++) {
      let startYear = 2000;
      newYearList.push(startYear + index);
    }
    setYearList(newYearList);
    let presentMonth = new Date().getMonth() + 1;
    let monthNumber = presentMonth;

    if (presentMonth < 10) {
      monthNumber = `0${presentMonth}`;
    }
    setMonth(monthNumber);
    setTotalViewShow(true);
  };

  useEffect(() => {
    getPresentMonth();
    getData();
  }, []);
  return (
    <div>
      <Grid container>
        <Grid item xs={2}>
          <Typography
            variant="h6"
            component="div"
            style={{ position: "relative", fontSize: "18px" }}
          >
            Billing
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={month}
                  label="Month"
                  onChange={(e) => setMonth(e.target.value)}
                >
                  {monthList?.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  {yearList?.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={{ lg: 1, xl: 1 }}>
                <Grid item xs={6}>
                  <Button
                    style={{ minWidth: "0px" }}
                    variant="contained"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    disableElevation
                    // color="success"
                    style={{ background: "#F26522", minWidth: "0px" }}
                    size="large"
                    fullWidth
                    onClick={(event) => handleSearch()}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <hr />
      <br />
      {loading ? (
        <Skeleton variant="rectangular" height={220} />
      ) : (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Days Spent</TableCell>
              <TableCell>Time Spent</TableCell>
              <TableCell style={{ width: "290px" }}>Charge</TableCell>
              <TableCell style={{ width: "150px" }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {monthlyAttendenceReport?.totalDaysSpentInTheMonth}
              </TableCell>
              <TableCell>
                {monthlyAttendenceReport?.totalTimeSpentInTheMonth}
              </TableCell>
              <TableCell>
                <HourlyRates setTotalViewShow={setTotalViewShow} />
              </TableCell>
              <TableCell>
                {totalViewShow && <TotalBill newDate={`${year}-${month}-01`} />}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {!loading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            disableElevation
            disabled={invoiceLoading}
            style={{ fontSize: "11px", minWidth: "145px" }}
            variant="outlined"
            size="small"
            color="success"
            startIcon={<ReceiptOutlinedIcon />}
            onClick={generateInvoice}
          >
            {invoiceLoading === false && "Generate Invoice"}
            <PulseLoader
              color={"#353b48"}
              loading={invoiceLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
          &nbsp;
          <Button
            disableElevation
            disabled={invoiceLoading}
            style={{ fontSize: "11px", minWidth: "145px" }}
            variant="outlined"
            size="small"
            startIcon={<FileCopyOutlinedIcon />}
            onClick={getAllInvoice}
          >
            {invoiceLoading === false && "Show All Invoice"}
            <PulseLoader
              color={"#353b48"}
              loading={invoiceLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">{"Invoice List"}</DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Billing Month
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }} align="center">
                  Payment Type
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Amount Payable
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Status</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Updated At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tableDataList.length > 0 &&
                tableDataList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row?.billingMonth}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} align="center">
                      {row?.paymentType === null ? "-----" : row?.paymentType}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row?.amountPayable}
                    </TableCell>
                    <TableCell align="center">
                      {row?.status ? (
                        <TaskAltOutlinedIcon style={{ color: "#10ac84" }} />
                      ) : (
                        <HighlightOffOutlinedIcon
                          style={{ color: "#ee5253" }}
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {moment(row?.updatedAt).format("YYYY-MM-DD")}
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && tableDataList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions style={{ paddingRight: "24px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Billing;

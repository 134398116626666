import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { makeStyles } from "@mui/styles";
import Navigation from "../navigation/Navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { getDataWithToken } from "../../services/GetDataService";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import RefreshToken from "../../services/RefreshToken";
import useMediaQuery from "@mui/material/useMediaQuery";
import SettingsIcon from "@mui/icons-material/Settings";
import { useSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import ClearIcon from "@mui/icons-material/Clear";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CampaignIcon from "@mui/icons-material/Campaign";
const useStyles = makeStyles((theme) => ({
  linkStyle: {
    textDecoration: "none",
    color: "#616A6B",
  },
  menuItem: {
    // color: "#fff",
    margin: "3px 10px !important",
    padding: "6px 16px !important",
    borderRadius: "10px !important",

    "& span": {
      fontSize: "16px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    ["& .MuiListItemIcon-root"]: {
      minWidth: "40px",
    },
    ["& .MuiSvgIcon-root"]: {
      position: "relative",
      top: "-2px",
      color: "#616A6B",
      fontSize: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
    "&:hover": {
      color: "#D5D8DC",
      background: "#273746 !important",
      // background: "#404040 !important",
      borderRadius: "10px",
      transition: ".3s",
      ["& .MuiSvgIcon-root"]: {
        color: "#D5D8DC",
      },
    },
  },
  menuItemActive: {
    // color: "rgba(15, 188, 249,1.0) !important",
    color: "#D5D8DC !important",
    background: "#273746 !important",
    // background:
    //   "linear-gradient(56deg, rgba(232,9,43,.5) 0%, rgba(232,9,43,.2) 100%) !important",
    // background: "rgb(255,255,255,0.2) !important",
    borderRadius: "10px !important",
    ["& .MuiSvgIcon-root"]: {
      color: "#D5D8DC !important",
    },
  },
  menuSubItem: {
    padding: "6px 32px 2px 38px !important",
    "& span": {
      fontSize: "16px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
    ["& .MuiListItemIcon-root"]: {
      minWidth: "40px",
    },
    ["& .MuiSvgIcon-root"]: {
      color: "#616A6B",
      fontSize: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
    "&:hover": {
      color: "#BDC3C7",
      ["& .MuiSvgIcon-root"]: {
        color: "#BDC3C7",
      },
    },
  },

  MuiDrawer: {
    backgroundColor: "#17202A !important",
    // backgroundColor: "#212529 !important",
    color: "#616A6B !important",
    // paddingRight: "7px",
    // paddingLeft: "7px",
  },
  menuTitle: {
    fontSize: "1rem",
    // marginLeft: "15px",
    // marginTop: "20px",
    margin: "5px 0px 15px 27px",
    color: "#7c7c7c",
    fontWeight: 500,
  },
  menuGroup: {
    // background: "green",
    borderBottom: "1px solid #273746",
    padding: "15px 0px",
    boxSizing: "border-box",
  },
  logoStyle: {
    position: "relative",
    top: "8px",
    left: "-11px",
    // cursor: "pointer",
    maxWidth: "200px",
  },
}));

const drawerWidth = 300;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Layout() {
  const classes = useStyles();
  let navigate = useNavigate();
  let pathname = useLocation().pathname;
  const theme = useTheme();
  const laptop = useMediaQuery(theme.breakpoints.down("xl"));
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [rollPermission, setRollPermission] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loginData, setLoginData] = useState({});
  const [childcarelist, setChildcareList] = useState([]);
  const [openChildcareDialog, setOpenChildcareDialog] = useState(false);
  const [chidlcareId, setChidlcareId] = useState();
  const [primaryDaycare, setPrimaryChildcare] = useState({});
  const [loading2, setLoading2] = useState(false);
  //sending reloadDashboardData for reload dashboard
  const [reloadDashboardData, setReloadDashboardData] = useState(false);
  //sending reloadFromLogin & setReloadFromLogin for reload useeffect functions
  const [reloadFromLogin, setReloadFromLogin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleChildcareDialogClose = () => {
    setOpenChildcareDialog(false);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const navigateRoutes = (routeName) => {
    navigate(routeName, { replace: true });
  };

  const fnLogout = () => {
    logout();
    setRollPermission([]);
    navigate("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const withoutLayout = [
    "/",
    "/admin-childcare-list",
    "/forgot-password",
    "/reset-password",
    "/verify",
    "/test",
  ];
  const handleChange = (event) => {
    setChidlcareId(event.target.value);
  };
  const getEmergencyCode = async () => {
    try {
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const emergencyCodeUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare/emergency-code/admin`;
      let myEmergencyCode = await getDataWithToken(emergencyCodeUrl, token);

      if (myEmergencyCode.status >= 200 && myEmergencyCode.status < 300) {
        if (myEmergencyCode.data < 1) {
          setMessage("No data found");
        }

        login({
          ...kinder_cubby_panel_user,
          myEmergencyCode: myEmergencyCode?.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const fnSetPrimaryDaycare = async () => {
    setLoading2(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/businesses/${chidlcareId}/primary`,
        method: "put",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        let newPrimaryChildcare = childcarelist.find(
          (el) => el.id === chidlcareId
        );

        setPrimaryChildcare(newPrimaryChildcare);
        setChidlcareId(newPrimaryChildcare.id);
        setOpenChildcareDialog(false);
        setReloadDashboardData(!reloadDashboardData);
        navigate("/dashboard");
        getEmergencyCode();
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.errors.toString(), "error");
      setLoading2(false);
    }
    setLoading2(false);
  };
  const getPrimaryChildcare = async () => {
    setPrimaryChildcare("");
    setChidlcareId("");
    if (kinder_cubby_panel_user.access_token !== undefined) {
      try {
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        const primaryChildcareUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/businesses/primary`;
        let primaryChildcare = await getDataWithToken(
          primaryChildcareUrl,
          token
        );
        if (primaryChildcare.status >= 200 && primaryChildcare.status < 300) {
          setPrimaryChildcare(primaryChildcare?.data);
          setChidlcareId(primaryChildcare?.data.id);

          if (primaryChildcare?.data.length < 1) {
            setMessage("No data found");
          }
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.message.toString(), "error");
      }
    }
  };
  const getChildcare = async (openDialogNow) => {
    setChildcareList([]);
    if (kinder_cubby_panel_user.access_token !== undefined) {
      try {
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        const allChildcareUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/businesses`;
        let allChildcare = await getDataWithToken(allChildcareUrl, token);

        if (allChildcare.status >= 200 && allChildcare.status < 300) {
          setChildcareList(allChildcare?.data);
          if (openDialogNow && allChildcare?.data.length > 1) {
            setOpenChildcareDialog(true);
          }
          if (allChildcare?.data.length < 1) {
            setMessage("No data found");
          }
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.message.toString(), "error");
      }
    }
  };
  const getData = async () => {
    try {
      if (kinder_cubby_panel_user.token) {
        // setLoading(true);
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        let userPermissions = await getDataWithToken(
          "api/user-permissions",
          token
        );

        if (userPermissions.data.code === 200) {
          setRollPermission(userPermissions.data.data.permissions);
        } else {
          setMessage(userPermissions.data.messages.toString());
          if (userPermissions.data.messages.length < 1) {
            setMessage("Something went wrong");
            // setLoading(false);
          }
          // setLoading(false);
        }
        // setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getData();
    getPrimaryChildcare();
    getChildcare();
  }, [reloadFromLogin]);

  const checkSuperAdmin = () => {
    let isSuperAdmin = false;

    kinder_cubby_panel_user?.roles?.map((e) => {
      if (e.name === "SUPER_ADMIN") {
        isSuperAdmin = true;
      }
    });
    return isSuperAdmin;
  };
  const checkDaycareAdmin = () => {
    let isDaycareAdmin = false;

    kinder_cubby_panel_user?.roles?.map((e) => {
      if (e.name === "DAYCARE_ADMIN") {
        isDaycareAdmin = true;
      }
    });
    return isDaycareAdmin;
  };

  if (withoutLayout.includes(pathname)) {
    return (
      <Navigation
        reloadFromLogin={reloadFromLogin}
        setReloadFromLogin={setReloadFromLogin}
        reloadDashboardData={reloadDashboardData}
        rollPermission={rollPermission}
        setRollPermission={setRollPermission}
      />
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <img
                  src="/image/logo.png"
                  alt=""
                  className={classes.logoStyle}
                />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    ml: 2,
                    borderRadius: "10px",
                    background: "#404040",
                    padding: "5px",
                    "&:hover": {
                      background: "#7c7c7c",
                    },
                  }}
                  // sx={{ mr: 2, ...(open && { display: "none" }) }}
                >
                  <MenuIcon sx={{ color: "#fff", fontSize: "26px" }} />
                </IconButton>
              </Typography>
              {/* <div>
            
            </div> */}
              {childcarelist?.length > 0 && (
                <div style={{ flexGrow: 1, textAlign: "center" }}>
                  <Button
                    variant="text"
                    onClick={() => {
                      getChildcare(true);
                    }}
                    endIcon={
                      <KeyboardArrowDownIcon
                        style={{
                          fontSize: "24px",
                          display: childcarelist.length < 2 && "none",
                        }}
                      />
                    }
                    style={{ color: "#D5D8DC" }}
                  >
                    {primaryDaycare?.name}
                  </Button>
                </div>
              )}
              <div style={{ flexGrow: 1, textAlign: "right" }}>
                <Button
                  id="basic-button"
                  aria-controls={menuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    ml: 2,
                    borderRadius: "25px",
                    background: "#404040",
                    padding: "5px",
                    "&:hover": {
                      background: "#7c7c7c",
                    },
                  }}
                >
                  &nbsp;{" "}
                  <PersonIcon style={{ fontSize: "28px", color: "#fff" }} />
                  <ArrowDropDownIcon style={{ color: "#fff" }} /> &nbsp;
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigateRoutes("/change-password");
                    }}
                  >
                    Change Password
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      fnLogout();
                    }}
                  >
                    Sign Out
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      handleClose();
                      fnLogout();
                    }}
                  >
                    <SettingsIcon />
                    Settings
                  </MenuItem> */}
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            classes={{ paper: classes.MuiDrawer }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader></DrawerHeader>
            <Divider />

            <List>
              {checkDaycareAdmin() && (
                <>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Dashboard</p>
                    <Link to="/dashboard" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/dashboard"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <DashboardIcon style={{ fontSize: "16px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Dashboard" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Teacher</p>
                    <Link to="/add-teacher" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/add-teacher"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <PersonAddAltIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Add Teacher" />
                      </ListItemButton>
                    </Link>

                    <Link to="/teacher-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/teacher-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <GroupsOutlinedIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Teacher List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Kid Management</p>
                    <Link to="/on-boarding" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/on-boarding"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <PersonAddAltIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="On Boarding" />
                      </ListItemButton>
                    </Link>

                    <Link to="/kid-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/kid-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <RecentActorsIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Kid List" />
                      </ListItemButton>
                    </Link>

                    <Link to="/parent-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/parent-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <PeopleAltOutlinedIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Parent List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Attendence Report</p>
                    <Link
                      to="/general-attendence-report"
                      className={classes.linkStyle}
                    >
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/general-attendence-report"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <AssessmentOutlinedIcon
                            style={{ fontSize: "22px" }}
                          />
                        </ListItemIcon>

                        <ListItemText primary="General Attendence Report" />
                      </ListItemButton>
                    </Link>
                    <Link
                      to="/monthly-attendence-report"
                      className={classes.linkStyle}
                    >
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/monthly-attendence-report"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <AssessmentOutlinedIcon
                            style={{ fontSize: "22px" }}
                          />
                        </ListItemIcon>

                        <ListItemText primary="Monthly Attendence Report" />
                      </ListItemButton>
                    </Link>
                  </div>

                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Books</p>
                    <Link to="/add-book" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/add-book"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <ImportContactsOutlinedIcon
                            style={{ fontSize: "22px" }}
                          />
                        </ListItemIcon>

                        <ListItemText primary="Add Book" />
                      </ListItemButton>
                    </Link>

                    <Link to="/book-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/book-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <BookOutlinedIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Book List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Goodreads</p>
                    <Link to="/goodreads-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/goodreads-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <LocalLibraryOutlinedIcon
                            style={{ fontSize: "22px" }}
                          />
                        </ListItemIcon>

                        <ListItemText primary="Goodreads List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Pickup Authorizations</p>
                    <Link
                      to="/pickup-authorization-list"
                      className={classes.linkStyle}
                    >
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/pickup-authorization-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <SupervisedUserCircleOutlinedIcon
                            style={{ fontSize: "22px" }}
                          />
                        </ListItemIcon>

                        <ListItemText primary="Authorizations List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Assessment</p>
                    <Link to="/category-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/category-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <FormatListBulletedOutlinedIcon
                            style={{ fontSize: "22px" }}
                          />
                        </ListItemIcon>

                        <ListItemText primary="Category List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Invoice</p>
                    <Link to="/invoice-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/invoice-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <ReceiptIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Invoice List" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Notification</p>
                    <Link to="/notification" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/notification"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <CampaignIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Notification" />
                      </ListItemButton>
                    </Link>
                  </div>
                </>
              )}
              {/* ============== Super admin routes menu ============== */}
              {checkSuperAdmin() && (
                <>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Dashboard</p>
                    <Link to="/admin-dashboard" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/admin-dashboard"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <DashboardIcon style={{ fontSize: "16px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Dashboard" />
                      </ListItemButton>
                    </Link>
                  </div>
                  <div className={classes.menuGroup}>
                    <p className={classes.menuTitle}>Child Care</p>
                    <Link to="/add-child-care" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/add-child-care"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <DomainAddOutlinedIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Add Child Care" />
                      </ListItemButton>
                    </Link>
                    <Link to="/child-care-list" className={classes.linkStyle}>
                      <ListItemButton
                        className={`${classes.menuItem} ${
                          pathname === "/child-care-list"
                            ? classes.menuItemActive
                            : null
                        }`}
                      >
                        <ListItemIcon>
                          <BusinessOutlinedIcon style={{ fontSize: "22px" }} />
                        </ListItemIcon>

                        <ListItemText primary="Child Care List" />
                      </ListItemButton>
                    </Link>
                  </div>
                </>
              )}
              {/* ============== Super admin routes menu ============== */}
            </List>
          </Drawer>
          <Main open={open}>
            <DrawerHeader />

            <Navigation
              reloadFromLogin={reloadFromLogin}
              setReloadFromLogin={setReloadFromLogin}
              reloadDashboardData={reloadDashboardData}
              rollPermission={rollPermission}
              setRollPermission={setRollPermission}
            />
          </Main>
        </Box>
        <Dialog
          open={openChildcareDialog}
          onClose={handleChildcareDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ position: "relative" }}>
            {"Please select a childcare"}{" "}
            <IconButton
              style={{ position: "absolute", right: 20, top: 12 }}
              onClick={handleChildcareDialogClose}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ minWidth: "350px" }}>
            {/* <Typography variant="h5" component="div">
              Childcare List
            </Typography>
            <hr />
            <br /> */}
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={chidlcareId}
                onChange={handleChange}
              >
                {childcarelist?.map((item, i) => (
                  <FormControlLabel
                    style={{
                      border: "1px solid #7c7c7c",
                      padding: "10px",
                      width: "350px",
                      margin: "0 0 10px",
                      borderRadius: "10px",
                    }}
                    key={i}
                    value={item.id}
                    control={<Radio />}
                    label={
                      <div>
                        <b>{item.name}</b> <br />
                        <span style={{ color: "#7c7c7c" }}>
                          {item?.city}-{item?.state}-{item?.country}
                        </span>
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <br />
            <br />
            <Button
              fullWidth
              variant="contained"
              disableElevation
              disabled={loading2}
              onClick={() => {
                fnSetPrimaryDaycare();
              }}
              style={{ minHeight: "37px" }}
            >
              {loading2 === false && "Continue"}
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
            </Button>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { IconButton, InputLabel } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import Collapse from "@mui/material/Collapse";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { makeStyles } from "@mui/styles";
const TeacherInformation = () => {
  const { slug } = useParams();
  const [teacherId, setTeacherId] = useState("");
  const [teacherDetails, setTeacherDetails] = useState({});
  const [teacherList, setTeacherList] = useState([]);
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [updateDialog, setUpdateDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [assignedTeacherIds, setAssignedTeacherIds] = useState([]);
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleUpdateDialogClose = () => {
    setUpdateDialog(false);
    setUpdateData("");
  };
  const getData = async () => {
    setLoading(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

    const allTeacherUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/dropdown/teacher`;
    let allData = await getDataWithToken(allTeacherUrl, token);

    if (allData.status >= 200 && allData.status < 300) {
      setTeacherList(allData.data);
      if (allData.data.length < 1) {
        setMessage("No data found");
      }
    }
    setLoading(false);
    return allData.data;
  };
  const getTeacherId = async () => {
    setLoading(true);
    setMessage2("");
    setActiveIndex(0);
    let allTeacher = await getData();
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const teacherIdUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/teacher`;
    let response = await getDataWithToken(teacherIdUrl, token);

    if (response.status >= 200 && response.status < 300) {
      if (response?.data.length > 0) {
        let firstElement = [];
        let otherElement = [];
        response?.data.map((item) => {
          if (item.status === true) {
            firstElement.push(item);
          } else {
            otherElement.push(item);
          }
        });
        const teacherStatusWiseArray = firstElement.concat(otherElement);
        setAssignedTeacherIds(teacherStatusWiseArray);

        setTeacherId(teacherStatusWiseArray[0].teacherId);
        let teacherData = allTeacher.find(
          (el) => el.id === teacherStatusWiseArray[0].teacherId
        );
        if (teacherData !== undefined) {
          setTeacherDetails(teacherData);
        }
      } else {
        setTeacherId("None");
        setMessage2("Please assign new teacher");
      }
    } else {
      setMessage(response.data.messages.toString());
      if (response.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
    setLoading(false);
  };
  const handleDialog = (value) => {
    setUpdateDialog(true);
    setUpdateData(value);
  };

  const handleUpdate = async () => {
    try {
      setLoading2(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/teacher/${updateData}`,
        method: "put",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Assigned successfully", "success");
        getTeacherId();

        // setTeacherId(updateData);
        // let teacherData = teacherList.filter((el) => el.id === updateData);
        // setTeacherDetails(teacherData[0]);
      }
      setUpdateDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading2(false);
      setUpdateDialog(false);
    }
  };
  const getNextAndPrevious = (type) => {
    if (type === "next") {
      if (assignedTeacherIds.length - 1 > activeIndex) {
        let id = assignedTeacherIds[parseInt(activeIndex + 1)].teacherId;
        setActiveIndex(parseInt(activeIndex + 1));
        let teacherInfo = teacherList.find((item) => item.id === id);
        if (teacherInfo !== undefined) {
          setTeacherDetails(teacherInfo);
        }
      }
    } else {
      if (activeIndex > 0) {
        let id = assignedTeacherIds[parseInt(activeIndex - 1)].teacherId;
        setActiveIndex(parseInt(activeIndex - 1));

        let teacherInfo = teacherList.find((item) => item.id === id);
        if (teacherInfo !== undefined) {
          setTeacherDetails(teacherInfo);
        }
      }
    }
  };
  useEffect(() => {
    // getData();
    getTeacherId();
  }, []);
  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs="auto">
          <Typography
            variant="h6"
            component="div"
            style={{ position: "relative", fontSize: "18px" }}
          >
            Teacher Information
            <IconButton onClick={() => setChecked(!checked)}>
              <PostAddOutlinedIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs="auto" style={{ textAlign: "right" }}>
          <IconButton
            size="small"
            disabled={activeIndex === 0}
            style={{
              color: "#999999",
              border: "1px solid #999999",
              marginRight: "5px",
              opacity: activeIndex === 0 ? 0.5 : 1,
            }}
            onClick={() => getNextAndPrevious("")}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          <IconButton
            size="small"
            disabled={assignedTeacherIds.length - 1 <= activeIndex}
            style={{
              color: "#999999",
              border: "1px solid #999999",
              // marginRight: "5px",
              opacity: assignedTeacherIds.length - 1 <= activeIndex ? 0.5 : 1,
            }}
            onClick={() => getNextAndPrevious("next")}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>

      <hr />
      <br />

      {message2.length > 0 ? (
        <>
          <FormControl fullWidth size="small" style={{ marginBottom: "10px" }}>
            <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
            <Select
              label="Assigned To"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={teacherId}
              onChange={(e) => handleDialog(e.target.value)}
            >
              <MenuItem value="None" disabled>
                None
              </MenuItem>
              {teacherList?.map((item, i) => (
                <MenuItem value={item.id} key={i}>
                  {item.firstName} {item.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "200px", background: "#f5f5f5" }}
          >
            <Typography
              variant="h5"
              component="div"
              style={{ color: "#999999" }}
            >
              {message2}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <div style={{ textAlign: "center", marginBottom: "15px" }}>
            {loading ? (
              <>
                <div style={{ margin: "auto", width: "70px" }}>
                  <Skeleton variant="circular" width={70} height={70} />
                </div>
                <div style={{ margin: "auto", width: "210px" }}>
                  <Skeleton variant="rectangular" width={210} />
                </div>
                <div style={{ margin: "auto", width: "250px" }}>
                  <Skeleton variant="rectangular" width={250} height={10} />
                </div>
              </>
            ) : (
              <>
                <img
                  src={
                    teacherDetails?.attributes?.photo?.length > 0
                      ? teacherDetails?.attributes?.photo[0]
                      : "/image/teacher.png"
                  }
                  alt=""
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />
                <Typography variant="h6">
                  {" "}
                  {teacherDetails?.firstName} {teacherDetails?.lastName}{" "}
                  <span style={{ fontSize: "12px", color: "#999999" }}>
                    {activeIndex !== 0 && "(Previous)"}
                  </span>
                </Typography>
                {teacherDetails?.email?.length && (
                  <div
                    style={{
                      color: "#999999",
                      fontSize: "18px",
                      marginTop: "-6px",
                    }}
                  >
                    <EmailOutlinedIcon
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "3px",
                        left: "-3px",
                      }}
                    />{" "}
                    {teacherDetails?.email}
                  </div>
                )}
              </>
            )}
          </div>
          {loading ? (
            <Skeleton variant="rectangular" height={150} />
          ) : (
            <>
              <Collapse in={checked}>
                <FormControl
                  fullWidth
                  size="small"
                  style={{ marginBottom: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Assigned To
                  </InputLabel>
                  <Select
                    label="Assigned To"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={teacherId}
                    onChange={(e) => handleDialog(e.target.value)}
                  >
                    <MenuItem value="None" disabled>
                      None
                    </MenuItem>
                    {teacherList?.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Collapse>
              <Grid container style={{ padding: "0px 20px" }}>
                {Object.keys(teacherDetails).length !== 0 && (
                  <>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Phone No
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.phoneNumber[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.street[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        City
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.locality[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        State
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.region[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.postalCode[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Country
                      </Typography>
                      <Typography variant="body2">
                        {teacherDetails?.attributes?.country[0]}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </>
      )}
      <Dialog
        open={updateDialog}
        onClose={handleUpdateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to assign a new teacher
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdateDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleUpdate}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default TeacherInformation;

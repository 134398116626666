import React, { useState, useContext } from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import RefreshToken from "../../services/RefreshToken";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
const LineChart = () => {
  const theme = useTheme();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const laptop = useMediaQuery(theme.breakpoints.down("xl"));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [list, setList] = useState([]);
  const [myOptions, setMyOptions] = useState({
    series: [
      {
        name: "Earning",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Earnings",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });

  var options = {
    series: [
      {
        name: "Earning",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Earnings",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };
  const getData = async (pageNO) => {
    try {
      setLoading(true);
      setMessage("");
      let dataSeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/earnings/childcare`;
      // const allDataUrl = `${
      //   process.env.REACT_APP_AUTH_URL
      // }/api/v1/private/kid/attendance?page=${newPageNO}&date=${moment(
      //   new Date()
      // ).format("YYYY-MM-DD")}`;
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let allData = await getDataWithToken(allDataUrl, token);
     
      if (allData.status >= 200 && allData.status < 300) {
        if (allData.data.length < 1) {
          setMessage("No Attendance found");
        } else {
          allData?.data?.map((item, i) => {
            
            let monthName = item.month.split("-");
          
            var index = monthList.indexOf(monthName[0]);
          
            dataSeries[index] = item.earning;
          });
         
          let newOptions = {
            ...myOptions,
            series: [{ name: "Earning", data: dataSeries }],
          };
          setMyOptions(newOptions); 
          setList(allData.data);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      // handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Chart
        options={myOptions}
        series={myOptions.series}
        type={myOptions.type}
        // width="380"
        height="600"
      />
    </div>
  );
};

export default LineChart;

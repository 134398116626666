import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../../services/GetDataService";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/Done";
import RefreshToken from "../../../../services/RefreshToken";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const HourlyRates = ({ setTotalViewShow }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const { slug } = useParams();
  const [hourlyRate, setRourlyRate] = useState("");
  const [chargeType, setChargeType] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const getData = async () => {
    setLoading(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const kidBillUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/rates`;

    let kidBill = await getDataWithToken(kidBillUrl, token);
    
    if (kidBill.status >= 200 && kidBill.status < 300) {
      setRourlyRate(kidBill?.data?.charge.toFixed(2));
      setChargeType(kidBill?.data?.per);
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      setTotalViewShow(false);
      if (hourlyRate.length > 0) {
        let formData = new FormData();
        formData.append("charge", parseFloat(hourlyRate).toFixed(2));
        formData.append("per", chargeType);
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/rates`,
          method: "put",
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Update successfully", "success");
        }
        setEdit(false);
        setRourlyRate(hourlyRate);
        setLoading(false);
      } else {
        handleSnakbarOpen("Please enter hourly rate", "error");
        setLoading(false);
      }
      setTotalViewShow(true);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {loading ? (
        <Skeleton variant="rectangular" />
      ) : (
        <>
          {edit ? (
            <>
              <Grid container justifyContent="center" alignItem="center">
                <Grid item xs={12}>
                  <TextField
                    // style={{ width: "220px" }}
                    fullWidth
                    type="number"
                    placeholder="Hourly Rate"
                    // label="Hourly Rate"
                    id="hourlyRate"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={hourlyRate}
                    onChange={(e) => setRourlyRate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%", marginLeft: "11px" }}>
                    <RadioGroup
                      row
                      style={{ justifyContent: "space-between" }}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={chargeType}
                      onChange={(e) => setChargeType(e.target.value)}
                    >
                      <FormControlLabel
                        value="Hour"
                        control={<Radio />}
                        label="Hour"
                      />
                      <FormControlLabel
                        value="Month"
                        control={<Radio />}
                        label="Month"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="success"
                    size="small"
                    disableElevation
                    onClick={() => handleUpdate()}
                    // style={{ minWidth: "0px", padding: "9.5px" }}
                  >
                    {/* <DoneIcon fontSize="small" /> */}
                    Update
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              $ {hourlyRate} / {chargeType} &nbsp;&nbsp;
              <IconButton
                size="small"
                onClick={() => setEdit(true)}
                // style={{ border: "1px solid #ddd" }}
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HourlyRates;

import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../home/Home";
import ForgotPassword from "../user-forms/ForgotPassword";
import Login from "../user-forms/Login";
import ResetPassword from "../user-forms/ResetPassword";
import Verify from "../user-forms/Verify";
import { AuthContext } from "../../context/AuthContext";
import AddTeacher from "../teacher/AddTeacher";
import UpdateTeacher from "../teacher/UpdateTeacher";
import TeacherList from "../teacher/TeacherList";
import NoMatch from "../NoMatch";
import Test from "../test/Test";
import KidList from "../kid-management/kid-list/KidList";
import KidDetailView from "../kid-management/kid-details/KidDetailView";
import ParentList from "../kid-management/parent/ParentList";
import UpdateParent from "../kid-management/parent/UpdateParent";
import OnBoarding from "../kid-management/on-boarding/OnBoarding";
import { isExpired, decodeToken } from "react-jwt";
import GeneralAttendenceReport from "../attendence-report/GeneralAttendenceReport";
import MonthlyAttendenceReport from "../attendence-report/MonthlyAttendenceReport";
import AddBook from "../books/AddBook";
import BookList from "../books/BookList";
import UpdateBook from "../books/UpdateBook";
import GoodReadsList from "../goodreads/GoodReadsList";
import KidListByTeacher from "../teacher/KidListByTeacher";
import KidListByParent from "../kid-management/parent/KidListByParent";
import DailyActivityFeed from "../kid-management/kid-list/DailyActivityFeed";
import PickupAuthorizationList from "../pickup-authorizations/PickupAuthorizationList";
import AddChildCare from "../child-care/AddChildCare";
import ChildCareList from "../child-care/ChildCareList";
import UpdateChildCare from "../child-care/UpdateChildCare";
import CategoryList from "../assessment/CategoryList";
import TopicList from "../assessment/TopicList";
import SuperAdminDashboard from "../home/SuperAdminDashboard";
import InvoiceList from "../invoice/InvoiceList";
import SendNotification from "../notification/SendNotification";

function SuperAdminRoute({ children }) {
  const { kinder_cubby_panel_user, logout } = useContext(AuthContext);

  let isSuperAdmin = false;

  kinder_cubby_panel_user?.roles?.map((e) => {
    if (e.name === "SUPER_ADMIN") {
      isSuperAdmin = true;
    }
  });

  const isRefreshTokenExpired = isExpired(
    kinder_cubby_panel_user.refresh_token
  );

  if (isSuperAdmin) {
    return children;
  } else {
    logout();
    return <Navigate to="/" />;
  }

  // return kinder_cubby_panel_user.access_token ? children : <Navigate to="/" />;
}
function PrivateRoute({ children }) {
  const { kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const isRefreshTokenExpired = isExpired(
    kinder_cubby_panel_user.refresh_token
  );
  let isDaycareAdmin = false;

  kinder_cubby_panel_user?.roles?.map((e) => {
    if (e.name === "DAYCARE_ADMIN") {
      isDaycareAdmin = true;
    }
  });

  if (isDaycareAdmin) {
    return children;
  } else {
    logout();
    return <Navigate to="/" />;
  }

  // return kinder_cubby_panel_user.access_token ? children : <Navigate to="/" />;
}
function RedirectToHome({ children }) {
  const { kinder_cubby_panel_user } = useContext(AuthContext);

  return !kinder_cubby_panel_user.access_token ? (
    children
  ) : (
    <Navigate to="/dashboard" />
  );
}
const Navigation = ({
  reloadFromLogin,
  setReloadFromLogin,
  reloadDashboardData,
  rollPermission,
  setRollPermission, 
}) => {
  const { kinder_cubby_panel_user } = useContext(AuthContext);

  return (
    <div>
      <Routes>
        <Route path="/test" element={<Test />} />
        <Route
          path="/"
          element={
            <RedirectToHome>
              <Login
                reloadFromLogin={reloadFromLogin}
                setReloadFromLogin={setReloadFromLogin} 
              />
            </RedirectToHome>
          }
        />
        <Route
          // path="verify"
          element={
            <RedirectToHome>
              <Verify setRollPermission={setRollPermission} />
            </RedirectToHome>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <Home
                reloadDashboardData={reloadDashboardData}
                rollPermission={rollPermission} 
              />
            </PrivateRoute>
          }
        />
        <Route
          path="add-teacher"
          element={
            <PrivateRoute>
              <AddTeacher />
            </PrivateRoute>
          }
        />
        <Route
          path="update-teacher/:slug"
          element={
            <PrivateRoute>
              <UpdateTeacher />
            </PrivateRoute>
          }
        />

        <Route
          path="teacher-list"
          element={
            <PrivateRoute>
              <TeacherList />
            </PrivateRoute>
          }
        />
        <Route
          path="add-book"
          element={
            <PrivateRoute>
              <AddBook />
            </PrivateRoute>
          }
        />
        <Route
          path="update-book"
          element={
            <PrivateRoute>
              <UpdateBook />
            </PrivateRoute>
          }
        />

        <Route
          path="book-list"
          element={
            <PrivateRoute>
              <BookList />
            </PrivateRoute>
          }
        />
        <Route
          path="goodreads-list"
          element={
            <PrivateRoute>
              <GoodReadsList />
            </PrivateRoute>
          }
        />
        <Route
          path="on-boarding"
          element={
            <PrivateRoute>
              <OnBoarding />
            </PrivateRoute>
          }
        />
        <Route
          path="kid-list"
          element={
            <PrivateRoute>
              <KidList />
            </PrivateRoute>
          }
        />
        <Route
          path="daily-activity-feed/:slug"
          element={
            <PrivateRoute>
              <DailyActivityFeed />
            </PrivateRoute>
          }
        />
        <Route
          path="kid-detail/:slug"
          element={
            <PrivateRoute>
              <KidDetailView />
            </PrivateRoute>
          }
        />
        <Route
          path="parent-list"
          element={
            <PrivateRoute>
              <ParentList />
            </PrivateRoute>
          }
        />
        <Route
          path="update-parent/:slug"
          element={
            <PrivateRoute>
              <UpdateParent />
            </PrivateRoute>
          }
        />
        <Route
          path="teacher-kid-list/:slug"
          element={
            <PrivateRoute>
              <KidListByTeacher />
            </PrivateRoute>
          }
        />
        <Route
          path="parent-kid-list/:slug"
          element={
            <PrivateRoute>
              <KidListByParent />
            </PrivateRoute>
          }
        />
        <Route
          path="general-attendence-report"
          element={
            <PrivateRoute>
              <GeneralAttendenceReport />
            </PrivateRoute>
          }
        />
        <Route
          path="monthly-attendence-report"
          element={
            <PrivateRoute>
              <MonthlyAttendenceReport />
            </PrivateRoute>
          }
        />
        <Route
          path="pickup-authorization-list"
          element={
            <PrivateRoute>
              <PickupAuthorizationList />
            </PrivateRoute>
          }
        />
        <Route
          path="category-list"
          element={
            <PrivateRoute>
              <CategoryList />
            </PrivateRoute>
          }
        />
        <Route
          path="topic-list/:cat/:slug"
          element={
            <PrivateRoute>
              <TopicList />
            </PrivateRoute>
          }
        />
        <Route
          path="invoice-list"
          element={
            <PrivateRoute>
              <InvoiceList />
            </PrivateRoute>
          }
        />
        <Route
          path="notification"
          element={
            <PrivateRoute>
              <SendNotification />
            </PrivateRoute>
          }
        />

        {/* ============== Super admin routes start ============== */}
        <Route
          path="admin-dashboard"
          element={
            <SuperAdminRoute>
              <SuperAdminDashboard />
            </SuperAdminRoute>
          }
        />
        <Route
          path="child-care-list"
          element={
            <SuperAdminRoute>
              <ChildCareList />
            </SuperAdminRoute>
          }
        />
        <Route
          path="add-child-care"
          element={
            <SuperAdminRoute>
              <AddChildCare />
            </SuperAdminRoute>
          }
        />
        <Route
          path="update-child-care"
          element={
            <SuperAdminRoute>
              <UpdateChildCare />
            </SuperAdminRoute>
          }
        />
        {/* ============== Super admin routes start ============== */}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route
          path="change-password"
          element={
            // <PrivateRoute>
            <ResetPassword rollPermission={rollPermission} />
            // </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            !kinder_cubby_panel_user.access_token ? (
              <Navigate to="/" />
            ) : (
              <NoMatch />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default Navigation;

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../../services/GetDataService";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import RefreshToken from "../../../../services/RefreshToken";
import { AuthContext } from "../../../../context/AuthContext";

const TotalBill = ({ newDate }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [totalBill, setTotalBill] = useState({});
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const getData = async (date) => {
    setLoading(true);
    try {
      let presentYear = new Date().getFullYear();
      let presentMonth = new Date().getMonth() + 1;
      if (presentMonth < 10) {
        presentMonth = `0${presentMonth}`;
      }

      let presentDate = `${presentYear}-${presentMonth}-01`;

      if (date) {
        presentDate = date;
      }
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const kidBillUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/billing?firstDateOfTheMonth=${newDate}`;

      let kidBill = await getDataWithToken(kidBillUrl, token);

      if (kidBill.status >= 200 && kidBill.status < 300) {
        setTotalBill(kidBill?.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {loading ? (
        <Skeleton variant="rectangular" />
      ) : (
        <>$ {totalBill?.monthlyBill}</>
      )}
    </div>
  );
};

export default TotalBill;

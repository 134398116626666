import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
const ParentList = () => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [mainDataList, setMainDataList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteData({});
  };

  const handleDeleteDialog = (i, row) => {
    setDeleteData({ index: i, row: row });
    setDeleteDialog(true);
  };
  const handleDelete = async () => {
    try {
      setLoading2(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/${deleteData.row.id}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Deleted successfully", "success");
        let newMainDataList = mainDataList.filter(
          (item) => item.id !== deleteData.row.id
        );

        setMainDataList(newMainDataList);
        setDataList(newMainDataList);

        let start = page * rowsPerPage;
        let end = (page + 1) * rowsPerPage;
        const displayData = newMainDataList.slice(start, end);

        if (displayData.length > 0) {
          setTableDataList(displayData);
        } else {
          setMessage("No data found");
        }
      }

      // tableDataList[deleteData.index].enable_deposit = false;
      setDeleteDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      // handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading2(false);
      setDeleteDialog(false);
    }
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(12).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    setLoading(true);

    setTimeout(() => {
      let start = newPage * rowsPerPage;
      let end = (newPage + 1) * rowsPerPage;
      const displayData = dataList.slice(parseInt(start), parseInt(end));
      setTableDataList(displayData);
      setPage(newPage);
      setLoading(false);
    }, 200);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    setTimeout(() => {
      let start = 0;
      let end = event.target.value;
      const displayData = dataList.slice(parseInt(start), parseInt(end));

      setTableDataList(displayData);
      setPage(0);
      setLoading(false);
    }, 200);

    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const getFilterData = () => {
    setLoading(true);

    let filterData = mainDataList;
    if (firstName.length > 0) {
      filterData = filterData.filter(
        (res) => res.firstName === firstName.trim()
      );
    }

    if (lastName.length > 0) {
      filterData = filterData.filter((res) => res.lastName === lastName.trim());
    }
    if (email.length > 0) {
      filterData = filterData.filter((res) => res.email === email.trim());
    }

    setTimeout(() => {
      setDataList(filterData);
      const displayData = filterData.slice(0, rowsPerPage);

      setTableDataList(displayData);
      setPage(0);
      if (displayData.length < 1) {
        setMessage("No data found");
      }
      setLoading(false);
    }, 200);
  };

  const getData = async () => {
    setLoading(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const parentUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/?page=0`;
    let allData = await getDataWithToken(parentUrl, token);

    if (allData.status >= 200 && allData.status < 300) {
      let myData = allData.data?.content;
      for (let index = 1; index < allData.data?.totalPages; index++) {
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
        const parentUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/?page=${index}`;
        let allData = await getDataWithToken(parentUrl, token);

        myData.concat(allData.data?.content);
      }
      setMainDataList(myData);
      setDataList(myData);

      let start = page * rowsPerPage;
      let end = (page + 1) * rowsPerPage;
      const displayData = myData?.slice(start, end);
      setTableDataList(displayData);

      if (displayData.length < 1) {
        setMessage("No data found");
      }
    }
    setLoading(false);
  };

  const clearFilter = (event) => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPage(0);
    getData(0);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          color="info"
          gutterBottom
          component="div"
          style={{ background: "#ddd", padding: "10px 16px" }}
        >
          <TableChartIcon
            style={{ position: "relative", top: "4px", fontSize: "20px" }}
          />{" "}
          Parent List
        </Typography>

        <div
          style={{
            overflowX: "auto",

            minWidth: "100%",
            // width: "Calc(100vw - 367px)",
            padding: "10px 16px 0px",
            boxSizing: "border-box",
          }}
        >
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={3}>
              <TextField
                label="First Name"
                id="firstName"
                variant="outlined"
                size="small"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Last Name"
                id="lastName"
                variant="outlined"
                size="small"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Email"
                id="email"
                variant="outlined"
                size="small"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={3}>
                <Grid item md={4} lg={3.5} xl={3}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item md={8} lg={8.5} xl={9}>
                  <Button
                    variant="contained"
                    disableElevation
                    style={{ background: "#F26522", padding: "6.7px 22px" }}
                    size="large"
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={(event) => getFilterData()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 382px)",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="80px" align="center">
                    Image
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    First Name
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    Last Name
                  </TableCell>
                  <TableCell>Phone No</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Street</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                  Zip Code
                  </TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  tableDataList.length > 0 &&
                  tableDataList.map((row, i) => (
                    <TableRow
                      key={i}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        <img
                          src={
                            row?.attributes?.photo?.length > 0
                              ? row?.attributes?.photo[0]
                              : "/image/NoImage.jpg"
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell>{row?.firstName}</TableCell>
                      <TableCell>{row?.lastName}</TableCell>
                      <TableCell>{row?.attributes?.phoneNumber[0]}</TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row?.email}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row?.attributes?.street[0]}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row?.attributes?.locality[0]}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row?.attributes?.region[0]}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row?.attributes?.postalCode}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row?.attributes?.country[0]}
                      </TableCell>
                      <TableCell align="center">
                        {row?.enabled ? (
                          <TaskAltOutlinedIcon style={{ color: "#10ac84" }} />
                        ) : (
                          <HighlightOffOutlinedIcon
                            style={{ color: "#ee5253" }}
                          />
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {/* <LightTooltip
                          title={`Kid list of ${row?.firstName} ${row?.lastName}`}
                          placement="top"
                        > */}
                        <IconButton
                          variant="contained"
                          disableElevation
                          component={Link}
                          to={`/parent-kid-list/${row?.id}`}
                          state={{ row }}
                        >
                          <ListAltOutlinedIcon />
                        </IconButton>
                        {/* </LightTooltip> */}
                        <IconButton
                          variant="contained"
                          // color="success"
                          disableElevation
                          component={Link}
                          to={`/update-parent/${row?.id}`}
                        >
                          <EditOutlinedIcon color="success" />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          // color="success"
                          disableElevation
                          // component={Link}
                          onClick={() => handleDeleteDialog(i, row)}
                        >
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                {!loading && tableDataList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
        </div>
        {tableDataList.length > 0 ? (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[10, 25, 50]}
              // count={rows.length}
              count={dataList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <br />
        )}
      </div>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to delete {deleteData?.row?.firstName}{" "}
              {deleteData?.row?.lastName}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleDelete}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ParentList;

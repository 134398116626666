import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link, useParams } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import axios from "axios";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RefreshToken from "../../services/RefreshToken";
import { AuthContext } from "../../context/AuthContext";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
const TopicList = () => {
  const { cat, slug } = useParams();

  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const [deleteData, setDeleteData] = useState({});
  const [title, setTitle] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [topicId, setTopicId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleAddDialogClose = () => {
    setAddDialog(false);
    setTitle("");
    setTotalMarks("");
    setStatus(true);
  };
  const handleEditDialogClose = () => {
    setEditDialog(false);
    setTitle("");
    setTotalMarks("");
    setStatus(true);
    setTopicId("");
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteData({});
  };

  const handleDeleteDialog = (i, row) => {
    setDeleteData({ index: i, row: row });
    setDeleteDialog(true);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(4).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };
  const validation = () => {
    let isError = false;

    if (!title.trim()) {
      handleSnakbarOpen("Please enter title", "error");
      document.getElementById("title").focus();
      return (isError = true);
    }
    if (totalMarks.length < 0) {
      handleSnakbarOpen("Please enter total marks", "error");
      document.getElementById("totalMarks").focus();
      return (isError = true);
    }

    return isError;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setAddLoading(true);
      try {
        let data = { title: title, totalMarks: totalMarks, status: status };
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/assessment/category/${slug}/topic`,
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Added new book successfully", "success");
          handleAddDialogClose();
          getData();
        }
      } catch (error) {
        console.log("error", error);
        setAddLoading(false);
        handleSnakbarOpen(error.response.data.errors, "error");
      }
      setAddLoading(false);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setEditLoading(true);

      try {
        let data = {
          title: title,
          totalMarks: parseInt(totalMarks),
          status: status,
        };
        console.log("data", data);
        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/assessment/category/${slug}/topic/${topicId}`,
          method: "put",
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Added new book successfully", "success");
          handleEditDialogClose();
          getData();
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errorMessage, "error");
        setEditLoading(false);
      }
      setEditLoading(false);
    }
  };
  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/assessment/category/${slug}/topic/${deleteData.row.id}`,
        // url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/assessment/category/${deleteData.row.id}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Deleted successfully", "success");
        setDeleteData({});
        getData();
      }
      setDeleteDialog(false);
      setDeleteLoading(false);
    } catch (error) {
      console.log("error", error);
      setDeleteLoading(false);
      handleSnakbarOpen(error.response.data.errors.toString(), "error");
      setDeleteDialog(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO) => {
    try {
      setLoading(true);
      let newPageNO = page;
      if (pageNO >= 0) {
        newPageNO = pageNO;
      }
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/assessment/category/${slug}/topic`;
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        setTableDataList(allData?.data);
        setRowsPerPage(allData?.data?.size);
        setTotalData(allData?.data?.totalElements);

        if (allData.data.length < 1) {
          setMessage("No data found");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.errors.toString(), "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          alignItems="center"
          style={{ background: "#ddd", padding: "8px 16px" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="body1"
              color="info"
              gutterBottom
              component="div"
              // style={{ background: "#ddd", padding: "10px 16px" }}
            >
              <TableChartIcon
                style={{ position: "relative", top: "4px", fontSize: "20px" }}
              />{" "}
              {cat} / Topic List
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              disableElevation
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={() => setAddDialog(true)}
            >
              Add New
            </Button>
          </Grid>
        </Grid>

        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            // width: "Calc(100vw - 367px)",
            padding: "10px 16px 0px",
            boxSizing: "border-box",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="center">Total Marks</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tableDataList.length > 0 &&
                tableDataList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row?.title}</TableCell>
                    <TableCell align="center">{row?.totalMarks}</TableCell>

                    <TableCell align="center">
                      {row?.status ? (
                        <TaskAltOutlinedIcon style={{ color: "#10ac84" }} />
                      ) : (
                        <HighlightOffOutlinedIcon
                          style={{ color: "#ee5253" }}
                        />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        variant="contained"
                        // color="success"
                        disableElevation
                        // component={Link}
                        // to={`/update-book`}
                        // state={{ row }}
                        onClick={() => {
                          setTopicId(row?.id);
                          setTitle(row?.title);
                          setTotalMarks(row?.totalMarks);
                          setStatus(row?.status);
                          setEditDialog(true);
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>

                      <IconButton
                        variant="contained"
                        disableElevation
                        onClick={() => handleDeleteDialog(i, row)}
                      >
                        <DeleteOutlineIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && tableDataList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
          <br />
        </div>
        {/* {tableDataList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )} */}
      </div>
      <Dialog
        open={addDialog}
        onClose={handleAddDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ minWidth: "350px", maxWidth: "350px" }}>
          {/* <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
              this is add dialog 
            </DialogContentText> */}

            <Typography variant="h5" component="div">
              Add Topic
            </Typography>
            <hr />

            <TextField
              style={{ marginTop: "10px", marginBottom: "20px" }}
              label="Title"
              id="title"
              variant="outlined"
              size="small"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              // style={{ marginBottom: "30px" }}
              label="Total Marks"
              id="totalMarks"
              variant="outlined"
              size="small"
              fullWidth
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
            {/* <FormControl
              fullWidth
              size="small"
              // style={{ marginBottom: "30px" }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl> */}
          </DialogContent>
          <DialogActions style={{ padding: "0px 24px 20px 24px" }}>
            <Button onClick={handleAddDialogClose}>cancel</Button>
            <Button
              disableElevation
              variant="contained"
              disabled={addLoading}
              onClick={onSubmit}
              style={{
                minWidth: "100px",
                minHeight: "35px",
                minWidth: "110px",
              }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={addLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {addLoading === false && "Continue"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={editDialog}
        onClose={handleEditDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ minWidth: "350px", maxWidth: "350px" }}>
          <DialogContent>
            <Typography
              variant="h5"
              component="div"
              // style={{ marginBottom: "30px" }}
            >
              Update Topic
            </Typography>
            <hr />

            <TextField
              style={{ marginTop: "10px", marginBottom: "20px" }}
              label="Title"
              id="title"
              variant="outlined"
              size="small"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              label="Total Marks"
              id="totalMarks"
              variant="outlined"
              size="small"
              fullWidth
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
            <FormControl
              fullWidth
              size="small"
              // style={{ marginBottom: "30px" }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ padding: "0px 24px 20px 24px" }}>
            <Button onClick={handleEditDialogClose}>cancel</Button>
            <Button
              disableElevation
              variant="contained"
              disabled={editLoading}
              onClick={handleUpdate}
              style={{
                minHeight: "35px",
                minWidth: "110px",
              }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={editLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {editLoading === false && "Update"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "400px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to delete <b>{deleteData?.row?.title}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={deleteLoading}
              onClick={handleDelete}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={deleteLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {deleteLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default TopicList;

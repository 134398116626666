import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import ColumnChart from "./ColumnChart";
import DonutChat from "./DonutChat";
import CircleChart from "./CircleChart";
import LineChart from "./LineChart";
import { Button, Typography } from "@mui/material";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { getDataWithToken } from "../../services/GetDataService";
import { useSnackbar } from "notistack";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthContext } from "../../context/AuthContext";
import RefreshToken from "../../services/RefreshToken";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px",
    background: "#fff",
    borderRadius: "10px",
    width: "100%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  listItem: {
    background: "#f5f5f5",
    padding: "15px 20px",
    borderRadius: "15px",
    marginBottom: "10px",
    // cursor: "pointer",
    color: "#17202A",
    "& a": {
      color: "#F26522 !important",
      border: "1px solid #F26522 !important",
      borderRadius: "10px",
      padding: "5px 10px 5px 15px",
      "&:hover": {
        border: "1px solid #F26522",
        // background: "#F26522",
      },
      [theme.breakpoints.down("xl")]: {
        fontSize: "12px",

        padding: "5px 5px 2px 10px !important",
      },
    },
  },
  listNameStyle: {
    fontSize: "16px",
    color: "#1E3677",
    fontWeight: 500,
    "& img": {
      height: "40px",
      width: "40px",
      borderRadius: "10px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
      "& img": {
        height: "30px",
        width: "30px",
        borderRadius: "10px",
      },
    },
  },

  attendanceTypeStyle: {
    fontWeight: 600,
    color: "#505050",
    fontSize: "15px",
    "& svg": {
      fontSize: "20px !important",
      position: "relative",
      top: 4,
      border: "2px solid #505050",
      borderRadius: "50%",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
      "& svg": {
        fontSize: "18px !important",
      },
    },
  },
  timeStyle: {
    fontWeight: 600,
    color: "#505050",
    fontSize: "15px",
    "& svg": {
      fontSize: "24px !important",
      position: "relative",
      top: 4,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
      "& svg": {
        fontSize: "22px !important",
      },
    },
  },
}));

const Home = ({ reloadDashboardData }) => {
  const classes = useStyles();
  const uploadImage = "/image/parent.jpg";
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [page, setPage] = useState(0);
  const [tableDataList, setTableDataList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  // const laptop = useMediaQuery(theme.breakpoints.down("xl"));
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const getData = async (pageNO) => {
    try {
      setLoading(true);
      setMessage("");
      let newPageNO = page;

      if (pageNO >= 0) {
        newPageNO = pageNO;
      }

      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/attendance?page=${newPageNO}`;
      // const allDataUrl = `${
      //   process.env.REACT_APP_AUTH_URL
      // }/api/v1/private/kid/attendance?page=${newPageNO}&date=${moment(
      //   new Date()
      // ).format("YYYY-MM-DD")}`;
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        if (allData.data.content.length < 1) {
          setMessage("No Attendance found");
        } else {
          setTableDataList(allData?.data?.content);
          setRowsPerPage(allData?.data?.size);
          setTotalData(allData?.data?.totalElements);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };
  const getDataOnScrolling = async (pageNO) => {
    let previousData = tableDataList;
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    let newPageNO = page + 1;

    setPage(newPageNO);
    const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/attendance?page=${newPageNO}`;
    let allData = await getDataWithToken(allDataUrl, token);
    if (allData.status >= 200 && allData.status < 300) {
      Array.prototype.push.apply(previousData, allData?.data?.content);
      setRefresh(!refresh);

      setTotalData(allData?.data?.totalElements);

      if (allData.data.content.length < 1) {
        setMessage("No data found");
      }
    }
  };

  const regenerateEmergencyCode = async (e) => {
    e.preventDefault();
    try {
      setLoading2(true);
      let formData = new FormData();
      // formData.append("firstName", firstName);

      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare/emergency-code/regenerate`,
        method: "put",
        // data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status >= 200 && response.status < 300) {
        login({
          ...kinder_cubby_panel_user,
          myEmergencyCode: response.data,
        });
        handleSnakbarOpen("Successfull", "success");
      }
    } catch (error) {
      console.log("error", error);
      setLoading2(false);
      handleSnakbarOpen(error.response.data.errors.toString(), "error");
    }
    setLoading2(false);
  };

  useEffect(() => {
    getData();
  }, [reloadDashboardData]);

  return (
    <div>
      <div
        style={{
          background: "#fcf8e3",
          border: "1px solid #faebcc",
          padding: "15px",
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#8a6d3b",
                margin: "0px 0px 10px",
              }}
            >
              Immediate Access
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="warning"
              disableElevation
              onClick={regenerateEmergencyCode}
            >
              Regenerate Emergency Code
            </Button>
          </Grid>
        </Grid>

        <Typography
          style={{
            fontSize: "30px",
            fontWeight: 500,
            color: "#8a6d3b",
            margin: "20px 0px 10px",
            letterSpacing: "20px",
          }}
        >
          {kinder_cubby_panel_user?.myEmergencyCode}
        </Typography>
        <hr style={{ border: "1px solid #faebcc" }} />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#8a6d3b",
            margin: "20px 0px 10px",
          }}
        >
          Above is your unique emergency access code, strictly available to you
          and teachers. In any emergency situation, authorities such as 911 or
          response teams may ask for this code. Our goal is to ensure that,
          during emergencies, responders have all the information they need at
          their fingertips. We are committed to providing a safe and secure
          environment for our children, and this portal serves as a crucial tool
          in achieving that objective. Please remember that the use of this
          service is reserved strictly for emergency situations, as the safety
          and privacy of our community is paramount.
        </Typography>
      </div>
      <br />
      <Grid container>
        <Grid
          item
          md={12}
          lg={6.9}
          xl={5.9}
          // style={{ background: "#fff", borderRadius: "10px", padding: "20px" }}
        >
          <div className={classes.form}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h5" component="div">
                  Attendance Events
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography variant="body2" component="div">
                  {moment(new Date()).format("MMM Do, YYYY")} &nbsp;&nbsp;
                  <CalendarMonthOutlinedIcon
                    style={{ color: "#999999", position: "relative", top: 5 }}
                  />
                </Typography>
              </Grid>
            </Grid>

            <br />
            <div
              id="attendence-events"
              style={{ maxHeight: "550px", overflowY: "scroll" }}
            >
              {message.length > 0 ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "250px", background: "#f5f5f5" }}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ color: "#999999" }}
                  >
                    {message}
                  </Typography>
                </Grid>
              ) : loading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => (
                  <Grid
                    key={i}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.listItem}
                    style={{ padding: "7px 20px" }}
                  >
                    <Grid item xs={4}>
                      <Grid container alignItems="center">
                        <Skeleton variant="circular" width={40} height={40} />
                        &nbsp;
                        <Skeleton variant="rectangular" width="60%" />
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container alignItems="center">
                        <Skeleton variant="circular" width={30} height={30} />{" "}
                        &nbsp;
                        <Skeleton variant="rectangular" width="60%" />
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container alignItems="center">
                        <Skeleton variant="circular" width={30} height={30} />{" "}
                        &nbsp;
                        <Skeleton variant="rectangular" width="60%" />
                      </Grid>
                    </Grid>

                    <Grid item xs={2}>
                      <Skeleton variant="rectangular" width="60%" />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <>
                  <InfiniteScroll
                    scrollableTarget="attendence-events"
                    dataLength={tableDataList.length}
                    next={getDataOnScrolling}
                    hasMore={tableDataList.length !== totalData}
                    loader={
                      <PulseLoader
                        color={"#353b48"}
                        loading={true}
                        size={15}
                        speedMultiplier={0.5}
                        style={{
                          textAlign: "center",
                          display: "block",
                          marginTop: "30px",
                        }}
                      />
                    }
                    // endMessage={
                    //   <h2 style={{ textAlign: "center" }}>
                    //     <b>You have seen it all</b>
                    //   </h2>
                    // }
                  >
                    {tableDataList?.map((item, i) => (
                      <Grid
                        key={i}
                        container
                        // justifyContent="space-between"
                        alignItems="center"
                        className={classes.listItem}
                      >
                        <Grid
                          item
                          md={4.5}
                          lg={4.5}
                          xl={4}
                          className={classes.listNameStyle}
                        >
                          <Grid container alignItems="center">
                            <img src={item?.photo} alt="" /> &nbsp;{" "}
                            {item?.firstName} {item?.lastName}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          xl={3}
                          className={classes.attendanceTypeStyle}
                        >
                          {item.attendanceType === "DroppedOff" ? (
                            <img
                              src="/image/droppedoff.svg"
                              alt=""
                              style={{
                                width: "20px",
                                position: "relative",
                                top: 4,
                              }}
                            />
                          ) : (
                            <img
                              src="/image/pickedup.svg"
                              alt=""
                              style={{
                                width: "20px",
                                position: "relative",
                                top: 4,
                              }}
                            />
                          )}
                          {/* <DirectionsWalkIcon
                            style={{
                              transform:
                                item.attendanceType === "DroppedOff"
                                  ? ""
                                  : "scaleX(-1)",
                            }}
                          />{" "} */}
                          &nbsp; {item.attendanceType}
                        </Grid>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          xl={3}
                          style={{ fontWeight: 600, color: "#505050" }}
                          className={classes.timeStyle}
                        >
                          <Grid container alignItems="center">
                            <Grid item>
                              <AccessTimeIcon
                                style={{
                                  fontSize: "24px",
                                  position: "relative",
                                  top: 4,
                                }}
                              />
                            </Grid>
                            <Grid item> &nbsp; {item.at}</Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={1.5} lg={1.5} xl={2}>
                          <Button
                            variant="outlined"
                            size="small"
                            endIcon={<ArrowRightIcon />}
                            component={Link}
                            to={`/kid-detail/${item.kidId}`}
                          >
                            View
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                  </InfiniteScroll>
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid item md={0} lg={0.2} xl={0.2}></Grid>
        <Grid
          item
          md={12}
          lg={4.9}
          xl={5.9}
          style={{ background: "#fff", borderRadius: "10px", padding: "20px" }}
        >
          <LineChart />
        </Grid>
      </Grid>
      {/* <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "80vh" }}
      >
        <form className={classes.form}>
          <p
            style={{
              color: "#464141",
              fontSize: "2.5rem",
              textAlign: "center",
              fontWeight: 700,
              fontFamily: "Open Sans,sans-serif",

              lineHeight: 1.5,
            }}
          >
            Easiest & holistic solution
            <br />
            for your childcare
          </p>
        </form>
      </Grid> */}
    </div>
  );
};

export default Home;

import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import KidInformation from "./KidInformation";
import ParentInformation from "./ParentInformation";
import TeacherInformation from "./TeacherInformation";
import Billing from "./billing/Billing";
import AuthorizedGuardianInformation from "./AuthorizedGuardianInformation";
import useMediaQuery from "@mui/material/useMediaQuery";
import KidAssessment from "./KidAssessment";
import FilesAndDocuments from "./FilesAndDocuments";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    width: "100%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    [theme.breakpoints.down("xl")]: {
      padding: "30px",
    },
  },
  gridStyle: {
    border: "1px solid #999999",
    padding: "20px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
}));
const KidDetailView = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:1536px)");
  // console.log("desktop", desktop);
  if (desktop) {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "80vh" }}
        >
          <div className={classes.form}>
            <Grid container>
              <Grid item xs={3.8} className={classes.gridStyle}>
                <KidInformation />
              </Grid>
              <Grid item xs={0.2}></Grid>
              <Grid item xs={3.8} className={classes.gridStyle}>
                <ParentInformation />
              </Grid>
              <Grid item xs={0.2}></Grid>
              <Grid item xs={3.8} className={classes.gridStyle}>
                <TeacherInformation />
              </Grid>
              <Grid item xs={12} style={{ height: "24px" }}></Grid>

              <Grid item xs={3.8} className={classes.gridStyle}>
                <Billing />
              </Grid>
              <Grid item xs={0.2}></Grid>

              <Grid item xs={3.8} className={classes.gridStyle}>
                <AuthorizedGuardianInformation />
              </Grid>
              <Grid item xs={0.2}></Grid>

              <Grid item xs={3.8} className={classes.gridStyle}>
                <KidAssessment />
              </Grid>
              <Grid item xs={12} style={{ height: "24px" }}></Grid>
              <Grid item xs={3.8} className={classes.gridStyle}>
                <FilesAndDocuments />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "80vh" }}
        >
          <div className={classes.form}>
            <Grid container>
              <Grid item xs={5.9} className={classes.gridStyle}>
                <KidInformation />
              </Grid>
              <Grid item xs={0.2}></Grid>
              <Grid item xs={5.9} className={classes.gridStyle}>
                <ParentInformation />
              </Grid>
              <Grid item xs={12} style={{ height: "16px" }}></Grid>
              <Grid item xs={5.9} className={classes.gridStyle}>
                <TeacherInformation />
              </Grid>
              <Grid item xs={0.2}></Grid>

              <Grid item xs={5.9} className={classes.gridStyle}>
                <Billing />
              </Grid>
              <Grid item xs={12} style={{ height: "16px" }}></Grid>

              <Grid item xs={5.9} className={classes.gridStyle}>
                <AuthorizedGuardianInformation />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </>
    );
  }
};

export default KidDetailView;

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useLocation, useParams } from "react-router-dom";
import UpdateParentInfo from "../kid-details/UpdateParentInfo";
import AddParentInfo from "../kid-details/AddParentInfo";
import { makeStyles } from "@mui/styles";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
const useStyles = makeStyles((theme) => ({
  cardTitle: {
    margin: 0,
    fontSize: "24px !important",
    lineHeight: 1.334,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      fontSize: "20px !important",
    },
  },
  cardSubtitle: {
    margin: 0,
    fontWeight: "500 !important",
    fontSize: "20px !important",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
  cardImgHolder: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "120px",
    width: "120px",
    [theme.breakpoints.down("xl")]: {
      height: "90px",
      width: "90px",
    },
  },
  cardImg: {
    height: "100%",
    width: "100%",
    border: "1px solid #ddd",
    padding: "5px",
    borderRadius: "10px",
  },
  rowStyle: {
    borderBottom: "1px solid #ddd",
    padding: "5px 0px !important",
  },
}));

const ParentDetails = () => {
  const { slug } = useParams();
  const { state } = useLocation();
  const classes = useStyles();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [parentAddForm, setParentAddForm] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const handleUpdateDialogClose = () => {
    setDialogOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      setMessage("");

      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let parentDetailUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/${slug}`;
      let parentdata = await getDataWithToken(parentDetailUrl, token);

      if (parentdata.status >= 200 && parentdata.status < 300) {
        setData(parentdata.data);
      } else {
        setMessage(parentdata.data.messages.toString());
        if (parentdata.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {" "}
      <Typography component="div" className={classes.cardTitle}>
        Guardian Information
        <IconButton
          style={{
            position: "absolute",
            color: "#999999",
            right: 0,
            top: -5,
            border: "1px solid #999999",
          }}
          onClick={() => setDialogOpen(true)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Typography>
      <hr />
      <br />
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "250px", background: "#f5f5f5" }}
        >
          <Typography variant="h5" component="div" style={{ color: "#999999" }}>
            {message}
          </Typography>
        </Grid>
      ) : (
        <>
          
         
          {/* new design start ------------------------ */}
          <>
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
              {loading ? (
                <>
                  <div style={{ margin: "auto", width: "70px" }}>
                    <Skeleton variant="circular" width={70} height={70} />
                  </div>
                  <div style={{ margin: "auto", width: "210px" }}>
                    <Skeleton variant="rectangular" width={210} />
                  </div>
                  <div style={{ margin: "auto", width: "250px" }}>
                    <Skeleton variant="rectangular" width={250} height={10} />
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={
                      data?.attributes?.photo?.length > 0
                        ? data?.attributes?.photo[0]
                        : "/image/NoImage.jpg"
                    }
                    alt=""
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography variant="h6">
                    {" "}
                    {data?.firstName} {data?.lastName}
                  </Typography>

                  <div
                    style={{
                      color: "#999999",
                      fontSize: "18px",
                      marginTop: "-6px",
                    }}
                  >
                    <EmailOutlinedIcon
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "3px",
                        left: "-3px",
                      }}
                    />{" "}
                    {data?.email}
                  </div>
                  {data?.enabled ? (
                    <div style={{ color: "#10ac84", fontSize: "18px" }}>
                      <TaskAltOutlinedIcon
                        style={{
                          position: "relative",
                          top: 4,
                          fontSize: "18px",
                        }}
                      />{" "}
                      <span>Active</span>
                    </div>
                  ) : (
                    <div style={{ color: "#ee5253", fontSize: "18px" }}>
                      <HighlightOffOutlinedIcon
                        style={{
                          position: "relative",
                          top: 4,
                          fontSize: "18px",
                        }}
                      />{" "}
                      <span>Inactive</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton variant="rectangular" height={150} />
                ) : (
                  <Grid container style={{ padding: "0px 20px" }}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Phone No
                      </Typography>
                      <Typography variant="body2">
                        {data?.attributes?.phoneNumber[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Street
                      </Typography>
                      <Typography variant="body2">
                        {data?.attributes?.street[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        City
                      </Typography>
                      <Typography variant="body2">
                        {data?.attributes?.locality[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        State
                      </Typography>
                      <Typography variant="body2">
                        {data?.attributes?.region[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Zip Code
                      </Typography>
                      <Typography variant="body2">
                        {data?.attributes?.postalCode[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingBottom: "5px",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="caption"
                        style={{
                          color: "#808080",
                        }}
                      >
                        Country
                      </Typography>
                      <Typography variant="body2">
                        {data?.attributes?.country[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
          {/* new design end ------------------------ */}
        </>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleUpdateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <div style={{ minWidth: "300px" }}>
          <DialogContent style={{ padding: 0 }}>
            {parentAddForm ? (
              <AddParentInfo
                kidId={state?.row?.kidId}
                handleUpdateDialogClose={handleUpdateDialogClose}
                getData={getData}
              />
            ) : (
              <UpdateParentInfo
                data={data}
                setDialogOpen={setDialogOpen}
                getData={getData}
                handleUpdateDialogClose={handleUpdateDialogClose}
              />
            )}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default ParentDetails;

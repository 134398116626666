import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Skeleton from "@mui/material/Skeleton";
import { useLocation, useParams } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import timelineStyles from "./DailyActivityFeed.module.css";
import Avatar from "@mui/material/Avatar";
import InfiniteScroll from "react-infinite-scroll-component";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";

const DailyActivityFeed = ({ rollPermission }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const { slug } = useParams();
  const { state } = useLocation();
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);

  const pageLoading = () => {
    return (
      <div className={timelineStyles.timeline}>
        {[1, 2, 3, 4, 5, 6].map((row, i) => {
          if (i % 2 === 0) {
            return (
              <div
                className={`${timelineStyles.container} ${timelineStyles.left}`}
              >
                <div className={timelineStyles.content}>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className={`${timelineStyles.container} ${timelineStyles.right}`}
              >
                <div className={timelineStyles.content}>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                </div>
              </div>
            );
          }
        })}
        ;
      </div>
    );
  };

  const getData = async (pageNO) => {
    setLoading(true);
    let newPageNO = page;

    if (pageNO >= 0) {
      newPageNO = pageNO;
    }
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/activity/daily/feed?page=${newPageNO}`;
    let allData = await getDataWithToken(allDataUrl, token);

    if (allData.status >= 200 && allData.status < 300) {
      setTableDataList(allData?.data?.data?.content);
      setRefresh(!refresh);
      //   setRowsPerPage(rowsPerPage + allData?.data?.data?.size);

      setTotalData(allData?.data?.data?.totalElements);

      if (allData.data.data.content.length < 1) {
        setMessage("No data found");
      }
    }
    setLoading(false);
  };
  const getDataOnScrolling = async (pageNO) => {
    let previousData = tableDataList;

    let newPageNO = page + 1;

    setPage(newPageNO);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/activity/daily/feed?page=${newPageNO}`;
    let allData = await getDataWithToken(allDataUrl, token);
    if (allData.status >= 200 && allData.status < 300) {
      Array.prototype.push.apply(previousData, allData?.data?.data?.content);
      setRefresh(!refresh);

      setTotalData(allData?.data?.data?.totalElements);

      if (allData.data.data.content.length < 1) {
        setMessage("No data found");
      }
    }
  };
  const getAge = () => {
    let DOB = state.row.dateOfBirth;

    const dobArray = DOB.split("-");

    let today = moment(new Date()).format("YYYY-MM-DD");
    const todayArray = today.split("-");
    let yearDiff = parseInt(todayArray[0]) - parseInt(dobArray[0]);
    let monthDiff = parseInt(todayArray[1]) - parseInt(dobArray[1]);
    let kidAge = Math.floor((yearDiff * 12 + monthDiff) / 12);

    return kidAge;
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Avatar
          alt=""
          src={state?.row?.photo}
          sx={{ width: 60, height: 60, margin: "auto" }}
        />
        <h1 style={{ margin: "0" }}>
          {state?.row?.firstName}&nbsp;
          {state?.row?.lastName}
        </h1>
        <h3 style={{ margin: "0 0 15px", color: "#6c6c6c" }}>
          {getAge()}+ years
        </h3>
      </div>

      {loading && tableDataList.length < 1 ? (
        pageLoading()
      ) : (
        <InfiniteScroll
          dataLength={tableDataList.length}
          next={getDataOnScrolling}
          hasMore={tableDataList.length !== totalData}
          loader={
            <PulseLoader
              color={"#353b48"}
              loading={true}
              size={15}
              speedMultiplier={0.5}
              style={{
                textAlign: "center",
                display: "block",
                marginTop: "30px",
              }}
            />
          }
          // endMessage={
          //   <h2 style={{ textAlign: "center" }}>
          //     <b>You have seen it all</b>
          //   </h2>
          // }
        >
          <div className={timelineStyles.timeline}>
            {
              // !loading &&
              // tableDataList.length > 0 &&
              tableDataList?.map((row, i) => {
                if (i % 2 === 0) {
                  return (
                    <div
                      className={`${timelineStyles.container} ${timelineStyles.left}`}
                    >
                      <div className={timelineStyles.content}>
                        <h2>
                          <img
                            src={row?.icon}
                            style={{
                              width: "30px",
                              position: "relative",
                              top: 4,
                            }}
                            alt=""
                          />{" "}
                          {row?.activity}
                        </h2>
                        <p>{row?.summary}</p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`${timelineStyles.container} ${timelineStyles.right}`}
                    >
                      <div className={timelineStyles.content}>
                        <h2>
                          <img
                            src={row?.icon}
                            style={{
                              width: "30px",
                              position: "relative",
                              top: 4,
                            }}
                            alt=""
                          />{" "}
                          {row?.activity}
                        </h2>
                        <p>{row?.summary}</p>
                      </div>
                    </div>
                  );
                }
              })
            }
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default DailyActivityFeed;

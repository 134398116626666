import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link, useLocation, useParams } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { AuthContext } from "../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import moment from "moment";

import axios from "axios";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import TeacherDetails from "./TeacherDetails";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RefreshToken from "../../services/RefreshToken";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    width: "100%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  cardTitle: {
    margin: 0,
    fontSize: "24px !important",
    lineHeight: 1.334,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      fontSize: "20px !important",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:first-child": {
      padding: "10px 16px",
    },
    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& td": {
    //     paddingTop: "12px",
    //     paddingBottom: "12px",
    //     padding: "12px 6px",
    //   },
    //   "& td:nth-child(n+3)": {
    //     paddingLeft: "0px",
    //   },
    // },
  },
  gridStyle: {
    border: "1px solid #999999",
    padding: "20px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
}));

const KidListByTeacher = ({ rollPermission }) => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const classes = useStyles();
  const { slug } = useParams();
  const { state } = useLocation();
  const [tableDataList, setTableDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [assignKidDialog, setAssignKidDialog] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [kidList, setKidList] = useState([]);
  const [kidId, setKidId] = useState("");
  const [kidListLoading, setKidListLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleAssignKidDialogClose = () => {
    setAssignKidDialog(false);
  };

  const handleAssignKidDialogOpen = (i, row) => {
    setAssignKidDialog(true);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 7; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(7).keys()].map((e, i) => (
            <TableCell key={i}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO) => {
    setLoading(true);
    let newPageNO = page;

    if (pageNO >= 0) {
      newPageNO = pageNO;
    }
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/teacher/${slug}/kid`;
    let allData = await getDataWithToken(allDataUrl, token);

    if (allData.status >= 200 && allData.status < 300) {
      setTableDataList(allData?.data);
      if (allData.data.length < 1) {
        setMessage("No data found");
      }
    }
    setLoading(false);
  };
  const getKidList = async () => {
    try {
      setKidListLoading(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/data/dropdown`;
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        setKidList(allData?.data);
      }
      setKidListLoading(false);
    } catch (error) {
      console.log("error", error);
      setKidListLoading(false);
    }
  };
  const handleUpdate = async () => {
    try {
      setLoading2(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${kidId}/teacher/${slug}`,
        method: "put",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        handleSnakbarOpen("Assigned successfully", "success");
        getData();
      }
      setAssignKidDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading2(false);
      setAssignKidDialog(false);
    }
  };
  useEffect(() => {
    getKidList();
    getData();
  }, []);

  return (
    <>
      <div className={classes.form}>
        <Grid container>
          <Grid item md={12} lg={4.9} xl={3.9} className={classes.gridStyle}>
            <TeacherDetails />
          </Grid>
          <Grid item md={0} lg={0.2} xl={0.2}></Grid>
          <Grid item md={12} lg={6.9} xl={7.9}>
            <div
              style={{
                background: "#fff",
                border: "1px solid #ddd",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Grid
                container
                style={{ background: "#ddd", padding: "10px 16px" }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    // color="info"
                    gutterBottom
                    // className={classes.cardTitle}
                    component="div"
                    style={{ margin: "5px 0px 0px 0px" }}
                  >
                    <TableChartIcon
                      style={{
                        position: "relative",
                        top: "4px",
                        fontSize: "20px",
                      }}
                    />{" "}
                    Assigned Kids
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Assaign New kid
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={kidId}
                      label="Assaign New kid"
                      onChange={(e) => {
                        handleAssignKidDialogOpen();
                        setKidId(e.target.value);
                      }}
                    >
                      {kidList?.map((item, i) => (
                        <MenuItem key={i} value={item.kidId}>
                          {item.firstName} {item.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <div
                style={{
                  overflowX: "auto",

                  minWidth: "100%",
                  // width: "Calc(100vw - 367px)",
                  padding: "10px 16px 0px",
                  boxSizing: "border-box",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="80px" align="center">
                        Image
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        First Name
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Last Name
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Date of Birth
                      </TableCell>

                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        Created At
                      </TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      tableDataList.length > 0 &&
                      tableDataList.map((row, i) => (
                        <TableRow
                          key={i}
                          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell align="center">
                            <img
                              src={
                                row?.photo.length > 0
                                  ? row?.photo
                                  : "/image/NoImage.jpg"
                              }
                              height="40px"
                              width="40px"
                              alt=""
                            />
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {row?.firstName}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {row?.lastName}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {row?.dateOfBirth}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(row?.createdAt).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.status ? (
                              <TaskAltOutlinedIcon
                                style={{ color: "#10ac84" }}
                              />
                            ) : (
                              <HighlightOffOutlinedIcon
                                style={{ color: "#ee5253" }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              variant="contained"
                              // color="success"
                              disableElevation
                              component={Link}
                              to={`/kid-detail/${row?.kidId}`}
                              state={{ row }}
                            >
                              <VisibilityOutlinedIcon />
                            </IconButton>

                            {/* <IconButton
                              variant="contained"
                              disableElevation
                              onClick={() => handleAssignKidDialog(i, row)}
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton> */}
                          </TableCell>
                        </TableRow>
                      ))}

                    {!loading && tableDataList.length < 1 ? (
                      <TableRow>
                        <TableCell colSpan={9} style={{ textAlign: "center" }}>
                          <strong> {message}</strong>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {loading && pageLoading()}
                  </TableBody>
                </Table>
              </div>
              <br />
              {/* {tableDataList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )} */}
            </div>
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={assignKidDialog}
        onClose={handleAssignKidDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "300px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to new kid
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAssignKidDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={loading2}
              onClick={handleUpdate}
              style={{ minWidth: "100px", minHeight: "35px" }}
              autoFocus
            >
              <PulseLoader
                color={"#353b48"}
                loading={loading2}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {loading2 === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default KidListByTeacher;

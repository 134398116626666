import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import FadeLoader from "react-spinners/FadeLoader";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import { countryList } from "../../data/CountryList";
import RefreshToken from "../../services/RefreshToken";
import Map from "../../map/Map";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "30px 50px",
    background: "#fff",
    borderRadius: "10px",

    // width: "1000px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
  },
  input: {
    display: "none",
  },
  gridStyle: {
    border: "1px solid #999999",
    padding: "10px 20px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
}));

const AddChildCare = () => {
  const classes = useStyles();
  const uploadImage = "/image/building.png";
  const defaultLatLng = { lat: 40.6892494, lng: -74.04450039999999 };
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [childCareName, setChildCareName] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [stateName, setStateName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("United States");
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(uploadImage);
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(true);
  const [marker, setMarker] = useState(defaultLatLng);
  const [center, setCenter] = useState(defaultLatLng);
  const navigate = useNavigate();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };

  const validation = () => {
    let isError = false;
    if (imageFile === null) {
      handleSnakbarOpen("Please upload a image", "error");
      return (isError = true);
    }
    if (!childCareName.trim()) {
      handleSnakbarOpen("Please enter child care name", "error");
      document.getElementById("childCareName").focus();
      return (isError = true);
    }

    if (!contactPersonName.trim()) {
      handleSnakbarOpen("Please enter contact person name", "error");
      document.getElementById("contactPersonName").focus();
      return (isError = true);
    }
    if (!contactPersonPhone.trim()) {
      handleSnakbarOpen("Please enter contact person phone number", "error");
      document.getElementById("contactPersonPhone").focus();
      return (isError = true);
    }

    if (!contactPersonEmail.trim()) {
      handleSnakbarOpen("Please enter contact person email address", "error");
      document.getElementById("contactPersonEmail").focus();
      return (isError = true);
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        contactPersonEmail.trim()
      )
    ) {
      handleSnakbarOpen("Invalid contact person email address", "error");
      document.getElementById("contactPersonEmail").focus();
      return (isError = true);
    }
    if (!country.trim()) {
      handleSnakbarOpen("Please select a country", "error");
      document.getElementById("country").focus();
      return (isError = true);
    }
    if (!street.trim()) {
      handleSnakbarOpen("Please enter street address", "error");
      document.getElementById("street").focus();
      return (isError = true);
    }
    if (!city.trim()) {
      handleSnakbarOpen("Please enter city", "error");
      document.getElementById("city").focus();
      return (isError = true);
    }
    if (!stateName.trim()) {
      handleSnakbarOpen("Please enter state", "error");
      document.getElementById("state").focus();
      return (isError = true);
    }

    if (!postalCode.trim()) {
      handleSnakbarOpen("Please enter zip code", "error");
      document.getElementById("postalCode").focus();
      return (isError = true);
    }
    // if (!address.trim()) {
    //   handleSnakbarOpen("Please enter address", "error");
    //   document.getElementById("address").focus();
    //   return (isError = true);
    // }

    if (defaultLatLng.lat === marker.lat || defaultLatLng.lng === marker.lng) {
      handleSnakbarOpen("Please select location from map", "error");
      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("name", childCareName);
        formData.append("address", address);
        formData.append("street", street);
        formData.append("city", city);
        formData.append("state", stateName);
        formData.append("postalCode", postalCode);
        formData.append("country", country);
        formData.append("contactPersonName", contactPersonName);
        formData.append("contactPersonEmail", contactPersonEmail);
        formData.append("contactPersonPhone", contactPersonPhone);
        formData.append("status", status);
        formData.append("latitude", marker.lat);
        formData.append("longitude", marker.lng);
        formData.append("logo", imageFile);

        let token = await RefreshToken(kinder_cubby_panel_user, logout, login);

        let response = await axios({
          url: `${process.env.REACT_APP_AUTH_URL}/api/v1/private/childcare`,
          method: "post",
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          handleSnakbarOpen("Added new child care successfully", "success");
          navigate("/child-care-list");
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.errors.toString(), "error");
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <form
        className={classes.form}
        // onSubmit={onSubmit}
      >
        <Typography
          variant="h5"
          component="div"
          // style={{ marginBottom: "30px" }}
        >
          Add Child Care
        </Typography>
        <hr />
        <br />
        <Grid container>
          <Grid item xs={5.9} className={classes.gridStyle}>
            <Typography
              variant="h6"
              component="div"
              style={{ fontWeight: 400 }}
            >
              Basic Information
            </Typography>
            <hr />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
                marginBottom: "19px",
              }}
            >
              <img
                src={preview}
                alt=""
                style={{
                  cursor: "pointer",
                  width: "135px",
                  height: "135px",
                  borderRadius: "50%",
                }}
                onClick={() =>
                  document.getElementById("contained-button-file").click()
                }
              />
              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={imageProcess}
              />
              <Button
                size="small"
                variant="outlined"
                style={{ marginTop: "5px", width: "135px" }}
                onClick={() =>
                  document.getElementById("contained-button-file").click()
                }
              >
                Upload
              </Button>
            </div>

            <div>
              <TextField
                label="Child Care Name"
                id="childCareName"
                variant="outlined"
                size="small"
                fullWidth
                value={childCareName}
                onChange={(e) => setChildCareName(e.target.value)}
              />
              <br />
              <br />
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid item xs={5.9} className={classes.gridStyle}>
            <Grid container alignItems="center">
              <Grid item md={12}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: 400 }}
                >
                  Contact Information
                </Typography>
                <hr />
              </Grid>

              <Grid item md={12}>
                <Alert severity="info">
                  A <b>Daycare Admin Account</b> will be created based on these
                  information. To help managing the daycare e.g. setting up
                  teacher accounts, kid onboarding, reporting, payments and
                  others - an automated email along with{" "}
                  <b>Admin Portal URL, Login Email</b> and <b>Password</b> will
                  be sent to the daycare admin email address.
                </Alert>
                <br />
                <TextField
                  label="Contact Person Name"
                  id="contactPersonName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={contactPersonName}
                  onChange={(e) => setContactPersonName(e.target.value)}
                />

                <br />
                <br />
                <TextField
                  label="Contact Person Phone"
                  id="contactPersonPhone"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={contactPersonPhone}
                  onChange={(e) => setContactPersonPhone(e.target.value)}
                />
                <br />
                <br />
                <TextField
                  label="Contact Person Email"
                  id="contactPersonEmail"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={contactPersonEmail}
                  onChange={(e) => setContactPersonEmail(e.target.value)}
                />
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ height: "24px" }}></Grid>
          <Grid item xs={5.9} className={classes.gridStyle}>
            <Typography
              variant="h6"
              component="div"
              style={{ fontWeight: 400 }}
            >
              Address
            </Typography>
            <hr />
            <br />
            <Grid
              container
              alignItems="center"
              rowSpacing={3}
              // className={classes.gridStyle}
            >
              <Grid item xs={5.8}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countryList?.map((item, i) => (
                      <MenuItem value={item.name} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={0.4}>
                {" "}
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  label="Street Address"
                  id="street"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Grid>

              <Grid item xs={5.8}>
                <TextField
                  label="Unit / Apartment No. / Suite"
                  id="unit"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={0.4}>
                {" "}
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  label="City"
                  id="city"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>

              <Grid item xs={5.8}>
                <TextField
                  label="State"
                  id="state"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={stateName}
                  onChange={(e) => setStateName(e.target.value)}
                />
              </Grid>
              <Grid item xs={0.4}>
                {" "}
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  label="Zip Code"
                  id="postalCode"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  style={{ background: "#ebebeb" }}
                  label="Latitude"
                  id="latitude"
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={marker.lat}
                />
              </Grid>
              <Grid item xs={0.4}>
                {" "}
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  style={{ background: "#ebebeb" }}
                  label="Longitude"
                  id="longitude"
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={marker.lng}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid item xs={5.9} className={classes.gridStyle}>
            <Map
              showSearch={true}
              marker={marker}
              setMarker={setMarker}
              center={center}
              setCenter={setCenter}
            />
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: "30px",
            textAlign: "right",
          }}
        >
          {/* <Button
            variant="text"
            style={{ marginRight: "10px" }}
            onClick={handleUpdateDialogClose}
          >
            Cancel
          </Button> */}
          <Button
            fullWidth
            variant="contained"
            disableElevation
            style={{
              minHeight: "37px",
            }}
            disabled={loading}
            onClick={onSubmit}
            // type="submit"
          >
            {loading === false && "Continue"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddChildCare;

import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import TableChartIcon from "@mui/icons-material/TableChart";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ClearIcon from "@mui/icons-material/Clear";
import { AuthContext } from "../../context/AuthContext";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import RefreshToken from "../../services/RefreshToken";
const GeneralAttendenceReport = () => {
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [tableDataList, setTableDataList] = useState([]);
  const [month, setMonth] = useState("");
  const [kidList, setKidList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [detailDialog, setDetailDialog] = useState(false);
  const [detailData, setDetailData] = useState({});

  const [date, setDate] = useState(null);
  const [kidId, setKidId] = useState("");
  const [kidListLoading, setKidListLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleSearch = () => {
    getData(0);
  };
  const clearFilter = () => {
    setKidId("");
    setDate(null);
    getData(0, true);
  };
  const handleDetailDialogClose = () => {
    setDetailDialog(false);
    setDetailData({});
  };

  const handleDetailDialog = (row) => {
    setDetailData(row);
    setDetailDialog(true);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          {[...Array(5).keys()].map(() => (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, noParams) => {
    try {
      setLoading(true);
      let newPageNO = page;

      if (pageNO >= 0) {
        newPageNO = pageNO;
      }
      let allDataUrl = new URL(
        `${
          process.env.REACT_APP_AUTH_URL
        }/api/v1/private/kid/attendance/report/daily?page=${parseInt(
          newPageNO
        )}`
      );
      if (!noParams) {
        if (kidId.length > 0) {
          allDataUrl.searchParams.append("kidId", kidId);
        }
        if (date !== null) {
          allDataUrl.searchParams.append(
            "date",
            dayjs(date).format("YYYY-MM-DD")
          );
        }
      }
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        setTableDataList(allData?.data?.content);
        setRowsPerPage(allData?.data?.size);
        setTotalData(allData?.data?.totalElements);

        if (allData.data.content.length < 1) {
          setMessage("No data found");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
  };

  const getKidList = async () => {
    try {
      setKidListLoading(true);
      let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
      const allDataUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/data/dropdown`;
      let allData = await getDataWithToken(allDataUrl, token);

      if (allData.status >= 200 && allData.status < 300) {
        setKidList(allData?.data);
      }
      setKidListLoading(false);
    } catch (error) {
      console.log("error", error);
      setKidListLoading(false);
    }
  };

  useEffect(() => {
    getKidList();
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          color="info"
          gutterBottom
          component="div"
          style={{ background: "#ddd", padding: "10px 16px" }}
        >
          <TableChartIcon
            style={{ position: "relative", top: "4px", fontSize: "20px" }}
          />{" "}
          General Attendence Report
        </Typography>

        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            padding: "10px 16px 0px",
            boxSizing: "border-box",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="right"
            spacing={4}
          >
            <Grid item xs={4.5}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  SELECT KID
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={kidId}
                  label="SELECT KID"
                  onChange={(e) => setKidId(e.target.value)}
                >
                  {kidList?.map((item, i) => (
                    <MenuItem key={i} value={item.kidId}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  inputFormat="YYYY-MM-DD"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
              {/* <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  {yearList?.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={3}>
                <Grid item md={4} lg={3.5} xl={3}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item md={8} lg={8.5} xl={9}>
                  <Button
                    variant="contained"
                    disableElevation
                    // color="success"
                    style={{ background: "#F26522", padding: "6.7px 22px" }}
                    size="large"
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={(event) => handleSearch()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Date</TableCell>

                <TableCell>Time Spent</TableCell>

                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                tableDataList.length > 0 &&
                tableDataList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row?.firstName}</TableCell>
                    <TableCell>{row?.lastName}</TableCell>
                    <TableCell>{row?.date}</TableCell>
                    <TableCell>
                      {" "}
                      <AccessTimeIcon
                        style={{
                          color: "#F26522",
                          fontSize: "20px",
                          position: "absolute",
                        }}
                      />{" "}
                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{row?.timeSpent}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        variant="contained"
                        disableElevation
                        onClick={() => handleDetailDialog(row)}
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && tableDataList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {tableDataList.length > 0 ? (
          <div>
            <TablePagination
              style={{ display: "block", border: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <br />
        )}
      </div>
      <Dialog
        open={detailDialog}
        onClose={handleDetailDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "500px" }}>
          <DialogTitle id="alert-dialog-title" style={{ position: "relative" }}>
            {"Logs"}
            <IconButton
              onClick={handleDetailDialogClose}
              style={{ position: "absolute", right: 17 }}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailData?.logs?.length > 0 ? (
                  detailData?.logs?.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {/* <DirectionsWalkIcon
                          style={{
                            color: "#8d8d8d",
                            fontSize: "18px",
                            position: "relative",
                            top: "4px",
                            borderRadius: "50%",
                            border: "1px solid #8d8d8d", 
                            transform:
                              item.attendanceType === "PickedUp"
                                ? "scaleX(-1)"
                                : "",
                          }}
                        />{" "} */}
                        {item.attendanceType === "DroppedOff" ? (
                          <img
                            src="/image/droppedoff.svg"
                            alt=""
                            style={{
                              width: "20px",
                              position: "relative",
                              top: 4,
                            }}
                          />
                        ) : (
                          <img
                            src="/image/pickedup.svg"
                            alt=""
                            style={{
                              width: "20px",
                              position: "relative",
                              top: 4,
                            }}
                          />
                        )}
                        &nbsp; &nbsp;{item.attendanceType}
                      </TableCell>
                      <TableCell>
                        <AccessTimeIcon
                          style={{
                            color: "#505050",
                            fontSize: "20px",
                            position: "absolute",
                          }}
                        />
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; {item.at}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center" }}>
                        <strong>No logs available</strong>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default GeneralAttendenceReport;

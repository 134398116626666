export const monthList = [
  {
    value: "01",
    name: "January",
  },
  {
    value: "02",
    name: "February",
  },
  {
    value: "03",
    name: "March",
  },
  {
    value: "04",
    name: "April",
  },
  {
    value: "05",
    name: "May",
  },
  {
    value: "06",
    name: "June",
  },
  {
    value: "07",
    name: "July",
  },
  {
    value: "08",
    name: "August",
  },
  {
    value: "09",
    name: "September",
  },
  {
    value: "10",
    name: "October",
  },
  {
    value: "11",
    name: "November",
  },
  {
    value: "12",
    name: "December",
  },
];

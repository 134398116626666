import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import UpdateParentInfo from "./UpdateParentInfo";
import AddParentInfo from "./AddParentInfo";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const ParentInformation = () => {
  const { slug } = useParams();
  const { login, kinder_cubby_panel_user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [parentAddForm, setParentAddForm] = useState(false);
  const [parentIds, setParentIds] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const handleUpdateDialogClose = () => {
    setDialogOpen(false);
  };

  const getData = async () => {
    setLoading(true);
    setMessage("");
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const parentIdUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/kid/${slug}/parent`;
    let response = await getDataWithToken(parentIdUrl, token);
    if (response.status >= 200 && response.status < 300) {
      if (response?.data.length > 0) {
        setParentIds(response?.data);
        getParentInfo(response?.data[0].parentId);
        setActiveIndex(0);
      } else {
        setParentAddForm(true);
        setMessage("Please add guardian information");
      }
    } else {
      setMessage(response.data.messages.toString());
      if (response.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
    setLoading(false);
  };
  const getParentInfo = async (parentId) => {
    setLoading(true);
    let token = await RefreshToken(kinder_cubby_panel_user, logout, login);
    const parentDetailUrl = `${process.env.REACT_APP_AUTH_URL}/api/v1/private/onboard/parent/${parentId}`;
    let parentdata = await getDataWithToken(parentDetailUrl, token);
    if (parentdata.status >= 200 && parentdata.status < 300) {
      setData(parentdata.data);
    } else {
      setMessage(parentdata.data.messages.toString());
      if (parentdata.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }

    setLoading(false);
  };

  const getNextAndPrevious = (type) => {
    if (type === "next") {
      if (parentIds.length - 1 > activeIndex) {
        let id = parentIds[parseInt(activeIndex + 1)].parentId;
        setActiveIndex(parseInt(activeIndex + 1));
        getParentInfo(id);
      }
    } else {
      if (activeIndex > 0) {
        let id = parentIds[parseInt(activeIndex - 1)].parentId;
        setActiveIndex(parseInt(activeIndex - 1));
        getParentInfo(id);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs="auto">
          <Typography
            variant="h6"
            component="div"
            style={{ position: "relative", fontSize: "18px" }}
          >
            Guardian Information
            <IconButton onClick={() => setDialogOpen(true)}>
              <EditOutlinedIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs="auto" style={{ textAlign: "right" }}>
          <IconButton
            size="small"
            disabled={activeIndex === 0}
            style={{
              color: "#999999",
              border: "1px solid #999999",
              marginRight: "5px",
              opacity: activeIndex === 0 ? 0.5 : 1,
            }}
            onClick={() => getNextAndPrevious("")}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          <IconButton
            size="small"
            disabled={parentIds.length - 1 <= activeIndex}
            style={{
              color: "#999999",
              border: "1px solid #999999",
              // marginRight: "5px",
              opacity: parentIds.length - 1 <= activeIndex ? 0.5 : 1,
            }}
            onClick={() => getNextAndPrevious("next")}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography
            variant="h6"
            component="div"
            style={{ position: "relative" }}
          >
            Guardian Information
            <IconButton onClick={() => setDialogOpen(true)}>
              <EditOutlinedIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <IconButton
            disabled={activeIndex === 0}
            style={{
              color: "#999999",
              border: "1px solid #999999",
              marginRight: "5px",
              opacity: activeIndex === 0 ? 0.5 : 1,
            }}
            onClick={() => getNextAndPrevious("")}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          <IconButton
            disabled={parentIds.length - 1 <= activeIndex}
            style={{
              color: "#999999",
              border: "1px solid #999999",
              // marginRight: "5px",
              opacity: parentIds.length - 1 <= activeIndex ? 0.5 : 1,
            }}
            onClick={() => getNextAndPrevious("next")}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid> */}

      <hr />
      <br />
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "250px", background: "#f5f5f5" }}
        >
          <Typography variant="h5" component="div" style={{ color: "#999999" }}>
            {message}
          </Typography>
        </Grid>
      ) : (
        <>
          <div style={{ textAlign: "center", marginBottom: "15px" }}>
            {loading ? (
              <>
                <div style={{ margin: "auto", width: "70px" }}>
                  <Skeleton variant="circular" width={70} height={70} />
                </div>
                <div style={{ margin: "auto", width: "210px" }}>
                  <Skeleton variant="rectangular" width={210} />
                </div>
                <div style={{ margin: "auto", width: "250px" }}>
                  <Skeleton variant="rectangular" width={250} height={10} />
                </div>
              </>
            ) : (
              <>
                <img
                  src={
                    data?.attributes?.photo?.length > 0
                      ? data?.attributes?.photo[0]
                      : "/image/NoImage.jpg"
                  }
                  alt=""
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />
                <Typography variant="h6">
                  {" "}
                  {data?.firstName} {data?.lastName}
                </Typography>

                <div
                  style={{
                    color: "#999999",
                    fontSize: "18px",
                    marginTop: "-6px",
                  }}
                >
                  <EmailOutlinedIcon
                    style={{
                      fontSize: "18px",
                      position: "relative",
                      top: "3px",
                      left: "-3px",
                    }}
                  />{" "}
                  {data?.email}
                </div>
              </>
            )}
          </div>
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" height={150} />
              ) : (
                <Grid container style={{ padding: "0px 20px" }}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Phone No
                    </Typography>
                    <Typography variant="body2">
                      {data?.attributes?.phoneNumber[0]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Street
                    </Typography>
                    <Typography variant="body2">
                      {data?.attributes?.street[0]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      City
                    </Typography>
                    <Typography variant="body2">
                      {data?.attributes?.locality[0]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      State
                    </Typography>
                    <Typography variant="body2">
                      {data?.attributes?.region[0]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Zip Code
                    </Typography>
                    <Typography variant="body2">
                      {data?.attributes?.postalCode[0]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="caption"
                      style={{
                        color: "#808080",
                      }}
                    >
                      Country
                    </Typography>
                    <Typography variant="body2">
                      {data?.attributes?.country[0]}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleUpdateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogContent style={{ padding: 0 }}>
          {parentAddForm ? (
            <AddParentInfo
              kidId={slug}
              handleUpdateDialogClose={handleUpdateDialogClose}
              getData={getData}
              setParentAddForm={setParentAddForm}
            />
          ) : (
            <UpdateParentInfo
              data={data}
              setDialogOpen={setDialogOpen}
              getData={getData}
              handleUpdateDialogClose={handleUpdateDialogClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ParentInformation;
